import React, { FC } from 'react';
import { PopUp } from '../types';

interface PopUpProps {
  popUp: PopUp;
  onDismiss: () => void;
  onClose: () => void;
}

export const Popup: FC<PopUpProps> = ({ popUp, onDismiss, onClose }) => {
  return (
    <div className="w-80 m-auto z-100 opacity-100 scale-100 duration-100 ease-in fixed top-1/3  inset-x-0 p-2 transition transform origin-top-right">
      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
        <img
          src={popUp.thumbnail}
          alt=""
          className="rounded-t-lg cursor-pointer"
          onClick={() => {
            window.open(popUp.link, '_blank');
          }}
        />
        <div className="flex justify-between text-center h-12 text-sm">
          <button className="w-1/2" onClick={onDismiss}>
            오늘 하루 안보기
          </button>
          <button className="w-1/2" onClick={onClose}>
            닫기
          </button>
        </div>
      </div>
    </div>
  );
};
