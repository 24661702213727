import {
  Avatar,
  BackButton,
  BottomFixedButton,
  Chip,
  Divider,
  FlexWrap,
  gql,
  LoadingScreen,
  Section,
  TextArea,
  TopNavbar,
  useIdParam,
  useMutation,
  useQuery,
} from '@entropyparadox/reusable-react';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Compliment, Review } from '../types';
import { ErrorPage } from './ErrorPage';

const EditReviewQuery = gql`
  query EditReviewQuery($id: Int!) {
    review(id: $id) {
      id
      content
      compliments {
        id
        text
      }
      pro {
        id
        name
        avatar
      }
    }
    compliments {
      id
      text
    }
  }
`;

const UpdateReview = gql`
  mutation UpdateReview($id: Int!, $content: String!, $complimentIds: [Int!]!) {
    updateReview(id: $id, content: $content, complimentIds: $complimentIds) {
      id
    }
  }
`;

export const ReviewEditPage = () => {
  const id = useIdParam();
  const history = useHistory();
  const [content, setContent] = useState('');
  const [selectedComplimentIds, setSelectedComplimentIds] = useState<number[]>(
    [],
  );

  const { loading, error, data } = useQuery<{
    review: Review;
    compliments: Compliment[];
  }>(EditReviewQuery, {
    variables: { id },
    onCompleted: ({ review }) => {
      const complimentsId = review.compliments.map(
        (compliment) => compliment.id,
      );
      setContent(review.content);
      setSelectedComplimentIds(complimentsId);
    },
  });

  const [updateReview] = useMutation(UpdateReview, {
    variables: { id, content, complimentIds: selectedComplimentIds },
    onCompleted: () => {
      alert('리뷰 수정이 완료되었습니다.');
      history.go(-1);
    },
  });

  if (loading) return <LoadingScreen />;
  if (error) return <ErrorPage />;

  return (
    <div>
      <TopNavbar title="리뷰 남기기" left={<BackButton />} />

      <div className="flex justify-between items-center p-5">
        <div className="mr-3">
          <Avatar src={data?.review.pro?.avatar} size={18} />
        </div>
        <div className="flex-1">
          <div className="font-bold text-13">
            {`${data?.review.pro?.name} 프로`}
          </div>
        </div>
      </div>

      <Divider />

      <Section>
        <h3 className="font-bold text-lg">
          레슨을 받으며 좋았던 점을 선택해주세요.
        </h3>
        <FlexWrap>
          {data?.compliments.map((compliment) => (
            <Chip
              key={compliment.id}
              text={compliment.text}
              onClick={() =>
                selectedComplimentIds.includes(compliment.id)
                  ? setSelectedComplimentIds(
                      selectedComplimentIds.filter(
                        (id: number) => id !== compliment.id,
                      ),
                    )
                  : setSelectedComplimentIds(
                      selectedComplimentIds.concat(compliment.id),
                    )
              }
              active={selectedComplimentIds.includes(compliment.id)}
            />
          ))}
        </FlexWrap>
        <Divider />
        <h3 className="font-bold text-lg">프로님과의 레슨이 어떠셨나요?</h3>
        <TextArea
          placeholder="100자 이내로 적어주세요."
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
        <div className="text-sm text-gray-500">
          *리뷰를 등록하면 프로 상세페이지에 노출됩니다.
        </div>
      </Section>

      <BottomFixedButton
        tw={{ height: 'h-16' }}
        disabled={!content || selectedComplimentIds.length === 0}
        onClick={() => updateReview()}
      >
        <div className="text-brand-2 font-bold">수정 완료</div>
      </BottomFixedButton>
    </div>
  );
};
