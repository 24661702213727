import React, { ButtonHTMLAttributes, FC } from 'react';

interface CheckButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  active: boolean;
}

export const CheckButton: FC<CheckButtonProps> = ({
  children,
  active,
  ...props
}) => {
  const backgroundColor = active ? 'bg-brand-1' : 'bg-gray-100';
  const textColor = active ? 'text-white' : 'text-gray-800';

  return (
    <button
      {...props}
      className={`p-2 h-16 min-w-16 ${backgroundColor} font-medium text-sm ${textColor}`}
    >
      {children}
    </button>
  );
};
