import {
  Avatar,
  BackButton,
  TopNavbar,
  Section,
  TextField,
  // TextArea,
  Button,
  LoadingScreen,
  useMutation,
  useRecoilState,
  List,
  SimpleListItem,
  useLogout,
  Divider,
  gql,
} from '@entropyparadox/reusable-react';
import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as Camera } from '../assets/images/icon-meedit-camera.svg';
import { meState } from '../store';
import { Role } from '../types';

const UpdateMe = gql`
  mutation UpdateMe($name: String, $password: String, $avatar: Upload) {
    updateMe(name: $name, password: $password, avatar: $avatar) {
      id
    }
  }
`;

export const MeEditPage = () => {
  const logout = useLogout();
  const [me, setMe] = useRecoilState(meState);
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  // const [introduction, setIntroduction] = useState('');
  const [avatar, setAvatar] = useState<File | null | undefined>();
  const avatarInput = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (!me) return;
    setName(me.name);
    // setIntroduction(me.introduction);
  }, [me]);

  const [updateMe] = useMutation(UpdateMe, {
    variables: {
      name,
      password,
      // introduction
      avatar,
    },
    onCompleted: () => {
      alert('수정이 완료되었습니다.');
      setMe(undefined);
    },
  });

  if (!me) return <LoadingScreen />;
  return (
    <>
      <TopNavbar title="내 정보 수정" left={<BackButton />} />
      {me.role === Role.USER && (
        <Section>
          <div className="flex items-center justify-center">
            <Avatar
              src={avatar ? URL.createObjectURL(avatar) : me.avatar}
              size={24}
              notification="bottom"
              notificationColor="brand-2"
              notificationImg={<Camera className="w-3 h-3" />}
              onClick={() => avatarInput.current?.click()}
            />
            <input
              ref={avatarInput}
              type="file"
              accept="image/*"
              className="hidden"
              onChange={(e) =>
                e.target.validity.valid && setAvatar(e.target.files?.item(0))
              }
            />
          </div>
        </Section>
      )}

      <Section>
        <TextField
          label="이름"
          placeholder="이름을 입력해주세요."
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        {!me.kakaoId && !me.appleId && (
          <>
            <TextField
              type="email"
              label="이메일 주소"
              disabled
              value={me.email}
            />
            <TextField
              type="password"
              label="비밀번호 변경"
              placeholder="비밀번호를 입력해주세요."
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              type="password"
              label="비밀번호 확인"
              placeholder="비밀번호를 다시 한 번 입력해주세요."
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
            />
          </>
        )}
        {/* <TextArea
          label="소개"
          placeholder="소개를 입력해주세요."
          value={introduction}
          onChange={(e) => setIntroduction(e.target.value)}
        /> */}
      </Section>

      <Section>
        <Button text="수정 완료" onClick={() => updateMe()} />
      </Section>

      <div className="h-10" />

      <List>
        <Divider />
        <SimpleListItem text="로그아웃" onClick={() => logout()} />
        {/* <SimpleListItem text="회원탈퇴" /> */}
      </List>
    </>
  );
};
