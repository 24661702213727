import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { ProVideo } from '../types';
import ReactPlayer from 'react-player';

interface ProVideoCardProps {
  proVideo: ProVideo;
  width?: string;
  subtitle: string;
}

export const ProVideoCard: FC<ProVideoCardProps> = ({
  proVideo,
  width = 'w-full',
  subtitle,
}) => {
  const history = useHistory();

  return (
    <div
      className={`${width} relative pb-1`}
      onClick={() => history.push(`/pro-videos/${proVideo.id}`)}
    >
      <div
        style={{
          position: 'relative',
          paddingTop: '83.33%',
        }}
      >
        <ReactPlayer
          url={proVideo.video}
          className="react-player"
          playing
          width="100%"
          height="100%"
          controls={false}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: '0.25rem',
            overflow: 'hidden',
          }}
          light
        />
      </div>
      <div className="mt-2 font-bold text-14">{proVideo.tag.name}</div>
      <div className="font-bold text-12">{subtitle}</div>
    </div>
  );
};
