import {
  BackButton,
  Divider,
  gql,
  LoadingScreen,
  SearchField,
  Section,
  Tab,
  Tabs,
  TopNavbar,
  useQuery,
} from '@entropyparadox/reusable-react';
import React, { useState } from 'react';
import { ProCard, ProVideoCard, NoSearchResult } from '../components';
import { ProVideo, User } from '../types';
import { ErrorPage } from './ErrorPage';

const SearchPageQuery = gql`
  query SearchPage($searchedValue: String!) {
    searchPros(value: $searchedValue) {
      id
      name
      callPrice
      livePrice
      avatar
    }
    searchProVideos(value: $searchedValue) {
      id
      video
      user {
        id
        name
      }
      tag {
        id
        name
      }
    }
    recommendedPros {
      id
      name
      callPrice
      livePrice
      exposureRank
      avatar
    }
    recommendedProVideos {
      id
      video
      user {
        id
        name
      }
      tag {
        id
        name
      }
    }
  }
`;

export const SearchPage = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [searchedValue, setSearchedValue] = useState('');

  const { loading, error, data } = useQuery<{
    searchPros: User[];
    searchProVideos: ProVideo[];
    recommendedPros: User[];
    recommendedProVideos: ProVideo[];
  }>(SearchPageQuery, {
    variables: { searchedValue },
  });

  if (loading) return <LoadingScreen />;
  if (error || !data) return <ErrorPage />;

  const recommendedProsData = data.recommendedPros
    .slice()
    .sort(function (a, b) {
      return 0.5 - Math.random();
    })
    .sort((a, b) => {
      if (a.exposureRank < b.exposureRank) {
        return -1;
      }
      if (a.exposureRank === null || b.exposureRank === null) {
        return 1;
      }
      return 0;
    })
    .sort((a, b) => {
      if (a.exposureRank === null || b.exposureRank === null) {
        return -1;
      }
      return 0;
    });

  const recommendedProVideosData = data.recommendedProVideos
    .slice()
    .sort(function (a, b) {
      return 0.5 - Math.random();
    });

  return (
    <>
      <TopNavbar
        left={<BackButton />}
        right={
          <SearchField
            autoFocus
            placeholder="나에게 필요한 키워드 찾기"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onSearch={() => searchValue && setSearchedValue(searchValue)}
            twOuter={{ minWidth: 'min-w-0' }}
            tw={{ minWidth: 'min-w-0' }}
          />
        }
        leftFlex="flex-none"
        borderless
      />

      <Tabs sticky tw={{ paddingX: 'px-3' }}>
        <Tab
          text={searchedValue ? '프로' : '추천 프로'}
          twInnerActive={{
            borderColor: 'border-brand-2',
            color: 'text-brand-2',
          }}
          active={tabIndex === 0}
          onClick={() => setTabIndex(0)}
        />
        <Tab
          text={searchedValue ? '영상' : '추천 영상'}
          twInnerActive={{
            borderColor: 'border-brand-2',
            color: 'text-brand-2',
          }}
          active={tabIndex === 1}
          onClick={() => setTabIndex(1)}
        />
      </Tabs>

      <Section>
        {tabIndex === 0 && (
          <>
            {searchedValue && (
              <>
                {data.searchPros.length > 0 ? (
                  data.searchPros.map((pro) => (
                    <ProCard key={pro.id} pro={pro} variant="detailed" />
                  ))
                ) : (
                  <NoSearchResult />
                )}
                <Divider />
                <h3 className="font-bold text-lg">추천 프로</h3>
              </>
            )}
            {recommendedProsData.map((pro) => (
              <ProCard key={pro.id} pro={pro} variant="detailed" />
            ))}
          </>
        )}

        {tabIndex === 1 && (
          <>
            {searchedValue && (
              <>
                {data.searchProVideos.length > 0 ? (
                  <div className="grid grid-cols-2 gap-x-3 gap-y-6">
                    {data.searchProVideos.map((proVideo) => (
                      <ProVideoCard
                        key={proVideo.id}
                        proVideo={proVideo}
                        subtitle={`${proVideo.user.name} 프로`}
                      />
                    ))}
                  </div>
                ) : (
                  <NoSearchResult />
                )}
                <Divider />
                <h3 className="font-bold text-lg">추천 영상</h3>
              </>
            )}
            <div className="grid grid-cols-2 gap-x-3 gap-y-6">
              {recommendedProVideosData.map((proVideo) => (
                <ProVideoCard
                  key={proVideo.id}
                  proVideo={proVideo}
                  subtitle={`${proVideo.user.name} 프로`}
                />
              ))}
            </div>
          </>
        )}
      </Section>
    </>
  );
};
