import {
  Avatar,
  gql,
  useMutation,
  useRecoilValue,
  utcToLocalFromNow,
} from '@entropyparadox/reusable-react';
import React, { FC, useState } from 'react';
import { QuestionPageQuery } from '../pages/QuestionPage';
import { meState } from '../store';
import { Comment } from '../types';
import { CommentEditor } from './CommentEditor';
import { SmallTextButton } from './SmallTextButton';

const DeleteComment = gql`
  mutation DeleteComment($id: Int!) {
    deleteComment(id: $id) {
      id
    }
  }
`;

interface CommentListItemProps {
  comment: Comment;
}

export const CommentListItem: FC<CommentListItemProps> = ({ comment }) => {
  const me = useRecoilValue(meState);
  const isMine = comment.user.id === me?.id;
  const [editing, setEditing] = useState(false);

  const [deleteComment] = useMutation(DeleteComment, {
    variables: { id: comment.id },
    refetchQueries: [
      { query: QuestionPageQuery, variables: { id: comment.questionId } },
    ],
  });

  return (
    <>
      <div className="flex space-x-3">
        <Avatar src={comment.user.avatar} />
        <div className="flex-1 flex flex-col">
          <span className="font-bold text-xs">{comment.user.name}</span>
          <span className="whitespace-pre-wrap text-msm text-gray-600">
            {comment.text}
          </span>
          <time className="text-xs text-gray-400">
            {utcToLocalFromNow(comment.createdAt)}
          </time>

          {isMine && (
            <div className="flex justify-end space-x-1">
              <SmallTextButton
                variant="edit"
                onClick={() => setEditing(true)}
              />
              <SmallTextButton
                variant="delete"
                onClick={() => deleteComment()}
              />
            </div>
          )}
        </div>
      </div>

      {editing && (
        <CommentEditor
          questionId={comment.questionId}
          comment={comment}
          onFinished={() => setEditing(false)}
        />
      )}
    </>
  );
};
