import {
  BackButton,
  Button,
  gql,
  LoadingScreen,
  Section,
  TextArea,
  TextField,
  TopNavbar,
  useIdParam,
  useMutation,
  useQuery,
} from '@entropyparadox/reusable-react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Question } from '../types';
import { ErrorPage } from './ErrorPage';
import { ReactComponent as VideoIcon } from '../assets/images/icon-upload.svg';

const QuestionEditPageQuery = gql`
  query QuestionEditPage($id: Int!) {
    question(id: $id) {
      id
      createdAt
      title
      text
      video
      user {
        id
        name
      }
    }
  }
`;

const CreateQuestion = gql`
  mutation CreateQuestion($title: String!, $text: String!, $video: Upload) {
    createQuestion(title: $title, text: $text, video: $video) {
      id
    }
  }
`;

const UpdateQuestion = gql`
  mutation UpdateQuestion(
    $id: Int!
    $title: String!
    $text: String!
    $video: Upload
  ) {
    updateQuestion(id: $id, title: $title, text: $text, video: $video) {
      id
    }
  }
`;

export const QuestionEditPage = () => {
  const history = useHistory();
  const id = useIdParam();
  const isNewQuestion = id === undefined;
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [video, setVideo] = useState<File | string | null | undefined>();

  const { loading, error, data } = useQuery<{
    question: Question;
  }>(QuestionEditPageQuery, {
    variables: { id },
    skip: isNewQuestion,
    onCompleted: ({ question }) => {
      setTitle(question.title);
      setText(question.text);
    },
  });

  const [createQuestion] = useMutation(CreateQuestion, {
    variables: { title, text, video },
    onCompleted: ({ createQuestion: { id } }) => {
      history.replace(`/questions/${id}`);
    },
  });

  const [updateQuestion] = useMutation(UpdateQuestion, {
    variables: { id, title, text, video },
    onCompleted: () => history.goBack(),
  });

  if (loading) return <LoadingScreen />;
  if (error) return <ErrorPage />;

  return (
    <>
      <TopNavbar title="Q&A" left={<BackButton />} />

      <Section>
        <div className="flex space-x-3">
          <div className="flex-1 flex flex-col">
            <span className="font-bold text-lg">[선택] 영상을 올려주세요.</span>
            <span className="text-sm text-gray-500">
              프로에게 질문할 영상을 찍어 올려주세요.
            </span>
          </div>
          <form>
            <div className="w-18">
              <input
                id="upload-video"
                hidden
                type="file"
                accept="video/*"
                onChange={(e) =>
                  e.target.validity.valid && setVideo(e.target.files?.item(0))
                }
              />
              <label htmlFor="upload-video">
                {video ? (
                  ''
                ) : data?.question.video ? (
                  <video>
                    <source src={data?.question.video} />
                  </video>
                ) : (
                  <VideoIcon />
                )}
              </label>
            </div>
          </form>
        </div>
      </Section>

      <Section>
        <TextField
          label="[필수] 제목을 작성해주세요."
          placeholder="제목"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </Section>

      <Section>
        <TextArea
          label="[필수] 질문을 작성해주세요."
          placeholder="내용"
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
      </Section>

      <Section>
        <Button
          text={isNewQuestion ? '등록하기' : '수정완료'}
          disabled={!title || !text}
          onClick={
            isNewQuestion ? () => createQuestion() : () => updateQuestion()
          }
        />
      </Section>
    </>
  );
};
