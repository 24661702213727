import React, { FC } from 'react';
import { ReactComponent as BodyIcon } from '../assets/images/icon-tag-category-body.svg';
import { ReactComponent as ClubIcon } from '../assets/images/icon-tag-category-club.svg';
import { ReactComponent as MotionIcon } from '../assets/images/icon-tag-category-motion.svg';
import { Category } from '../types';

interface CategoryIconProps {
  category: Category;
  color: string;
}

export const CategoryIcon: FC<CategoryIconProps> = ({ category, color }) => {
  if (category.name === '초급자') {
    return <BodyIcon className={`fill-current ${color}`} />;
  }
  if (category.name === '상급자') {
    return <ClubIcon className={`fill-current ${color}`} />;
  }
  if (category.name === '최상급자') {
    return <MotionIcon className={`fill-current ${color}`} />;
  }

  return <></>;
};
