import {
  appleIdState,
  BackButton,
  kakaoIdState,
  Section,
  TopNavbar,
  useResetRecoilState,
} from '@entropyparadox/reusable-react';
import React from 'react';
import { useHistory } from 'react-router-dom';

export const SignupPage = () => {
  const history = useHistory();
  const resetKakaoId = useResetRecoilState(kakaoIdState);
  const resetAppleId = useResetRecoilState(appleIdState);

  return (
    <>
      <TopNavbar
        title="회원가입"
        left={
          <BackButton
            onClick={() => {
              resetKakaoId();
              resetAppleId();
              history.goBack();
            }}
          />
        }
      />

      <Section>
        <button
          className="flex flex-col justify-center space-y-2 pl-6 h-28 rounded-xl bg-gray-50"
          onClick={() => history.push('/signup-form?isPro=false')}
        >
          <p className="font-bold">일반 회원가입</p>
          <p className="text-left text-sm text-gray-700">
            The Six Studio만의 차별화된 프로들의
            <br />
            원격레슨을 받아보세요.
          </p>
        </button>
        <button
          className="flex flex-col justify-center space-y-2 pl-6 h-28 rounded-xl bg-gray-50"
          onClick={() => history.push('/signup-form?isPro=true')}
        >
          <p className="font-bold">프로 회원가입</p>
          <p className="text-left text-sm text-gray-700">
            회원님들과의 레슨매칭, 스케줄관리를
            <br />
            The six studio에서 간편하게 시작해보세요.
          </p>
        </button>
      </Section>
    </>
  );
};
