import {
  Avatar,
  BackButton,
  Divider,
  gql,
  HomeButton,
  HorizontalScrollView,
  IconButton,
  LoadingScreen,
  Section,
  TopNavbar,
  useIdParam,
  useQuery,
} from '@entropyparadox/reusable-react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as RightArrowIcon } from '../assets/images/icon-right-arrow.svg';
import { ProVideoCard } from '../components/ProVideoCard';
import { ProVideo } from '../types';
import { ErrorPage } from './ErrorPage';
import { NotFoundPage } from './NotFoundPage';
import ReactPlayer from 'react-player';

const ProVideoPageQuery = gql`
  query ProVideoPage($id: Int!) {
    proVideo(id: $id) {
      id
      video
      user {
        id
        name
        avatar
        proVideos {
          id
          video
          tag {
            id
            name
          }
        }
      }
      tag {
        id
        name
      }
    }
  }
`;

export const ProVideoPage = () => {
  const id = useIdParam();
  const history = useHistory();
  const { loading, error, data } = useQuery<{ proVideo: ProVideo }>(
    ProVideoPageQuery,
    {
      skip: id === undefined,
      variables: { id },
    },
  );

  if (!id) return <NotFoundPage />;
  if (loading) return <LoadingScreen />;
  if (error || !data) return <ErrorPage />;

  return (
    <>
      <TopNavbar
        title={data.proVideo.tag.name}
        left={<BackButton />}
        right={<HomeButton />}
      />
      <div
        style={{
          position: 'relative',
          paddingTop: '56.25%',
        }}
      >
        <ReactPlayer
          url={data.proVideo.video}
          className="react-player"
          playing
          width="100%"
          height="100%"
          controls={false}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
          }}
          light
        />
      </div>
      <Section>
        <div className="flex items-center justify-between">
          <div className="flex space-x-2 items-center">
            <Avatar src={data.proVideo.user.avatar} size={10} />
            <div className="font-bold">{data.proVideo.user.name} 프로</div>
          </div>
          <div
            className="flex items-center -mr-2"
            onClick={() => history.push(`/pros/${data.proVideo.user.id}`)}
          >
            <span>레슨 예약</span>
            <IconButton icon={<RightArrowIcon />} />
          </div>
        </div>
      </Section>
      <Divider tw={{ height: 'h-3' }} />
      <Section>
        <h3 className="font-bold text-base">영상</h3>
        <HorizontalScrollView
          twInner={{ marginX: '-mx-5', marginLeft: 'ml-0' }}
        >
          {data.proVideo.user.proVideos
            .filter((proVideo) => proVideo.id !== id)
            .map((proVideo) => (
              <ProVideoCard
                key={proVideo.id}
                proVideo={proVideo}
                width="w-32"
                subtitle=""
              />
            ))}
        </HorizontalScrollView>
      </Section>
    </>
  );
};
