import { AspectRatioImage, Button } from '@entropyparadox/reusable-react';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as VideoIcon } from '../assets/images/icon-lesson-video.svg';
import { ReactComponent as VoiceIcon } from '../assets/images/icon-lesson-voice.svg';
import { User } from '../types';

interface ProCardProps {
  pro: User;
  variant?: 'simple' | 'detailed';
}

export const ProCard: FC<ProCardProps> = ({ pro, variant = 'simple' }) => {
  const history = useHistory();

  const image = (
    <AspectRatioImage
      src={pro.avatar}
      alt="pro"
      pb={variant === 'simple' ? 'pb-2/3' : 'pb-4/3'}
      rounded="rounded"
    />
  );

  const name = <span className="truncate font-bold">{`${pro.name} 프로`}</span>;

  const pricing = (
    <div className="flex flex-col space-y-1">
      <div className="flex items-center space-x-1">
        <VoiceIcon />
        <span className="font-bold text-xs text-gray-500">15분 전화</span>
        <span className="text-xs text-gray-500">{`${pro.callPrice.toLocaleString()}원`}</span>
      </div>

      <div className="flex items-center space-x-1">
        <VideoIcon />
        <span className="font-bold text-xs text-gray-500">15분 화상</span>
        <span className="text-xs text-gray-500">{`${pro.livePrice.toLocaleString()}원`}</span>
      </div>
    </div>
  );

  if (variant === 'simple') {
    return (
      <div
        className="flex flex-col space-y-2"
        onClick={() => history.push(`/pros/${pro.id}`)}
      >
        {image}
        {name}
        {pricing}
      </div>
    );
  }

  return (
    <div
      className="flex space-x-3"
      onClick={() => history.push(`/pros/${pro.id}`)}
    >
      <div className="flex-1">{image}</div>
      <div className="flex-1 flex flex-col space-y-2">
        {name}
        <p className="flex-1 text-xs text-gray-500">이력</p>
        {pricing}
        <Button
          text="예약하기 >"
          variant="outlined"
          tw={{
            borderColor: 'border-brand-2',
            borderRadius: 'rounded',
            color: 'text-brand-2',
            fontSize: 'text-xs',
            height: 'h-8',
          }}
        />
      </div>
    </div>
  );
};
