import {
  BackButton,
  Button,
  PhoneNumberField,
  Section,
  TopNavbar,
} from '@entropyparadox/reusable-react';
import React, { useState } from 'react';

export const PasswordResetPage = () => {
  const [phone, setPhone] = useState('');
  return (
    <>
      <TopNavbar title="비밀번호 변경" left={<BackButton />} />
      <div className="text-center py-6 font-bold text-lg">
        비밀번호 설정을 위해
        <br />
        휴대폰 번호를 인증해주세요.
      </div>
      <Section>
        <PhoneNumberField
          label="핸드폰 번호"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <Button variant="outlined" disabled={!phone}>
          인증번호 보내기
        </Button>
      </Section>
    </>
  );
};
