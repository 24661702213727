import React, { ButtonHTMLAttributes, FC } from 'react';
import { Category } from '../types';
import { CategoryIcon } from './CategoryIcon';

interface CategoryButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  category: Category;
}

export const CategoryButton: FC<CategoryButtonProps> = ({
  category,
  ...props
}) => {
  return (
    <div className="flex flex-col items-center space-y-2">
      <button
        className="flex justify-center items-center w-24 h-24
                   rounded-full bg-gray-50"
        {...props}
      >
        <CategoryIcon category={category} color="text-gray-600" />
      </button>
      <span className="font-medium text-15">{category.name}</span>
    </div>
  );
};
