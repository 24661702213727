import {
  Avatar,
  BackButton,
  Divider,
  gql,
  LoadingScreen,
  Section,
  TextField,
  TopNavbar,
  useIdParam,
  useMutation,
  useQuery,
  useRecoilValue,
  utcToLocalDatetime,
} from '@entropyparadox/reusable-react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  AnsweredIndicator,
  CommentEditor,
  CommentListItem,
  SmallTextButton,
} from '../components';
import { meState } from '../store';
import { Question, Role } from '../types';
import { ErrorPage } from './ErrorPage';
import { NotFoundPage } from './NotFoundPage';
import ReactPlayer from 'react-player';

export const QuestionPageQuery = gql`
  query QuestionPage($id: Int!) {
    question(id: $id) {
      id
      createdAt
      title
      text
      video
      answered
      user {
        id
        name
        avatar
      }
      comments {
        id
        createdAt
        text
        questionId
        user {
          id
          name
          avatar
        }
      }
    }
  }
`;

const DeleteQuestion = gql`
  mutation DeleteQuestion($id: Int!) {
    deleteQuestion(id: $id) {
      id
    }
  }
`;

export const QuestionPage = () => {
  const history = useHistory();
  const id = useIdParam();
  const me = useRecoilValue(meState);
  const [commentEditing, setCommentEditing] = useState(false);

  const { loading, error, data } = useQuery<{
    question: Question;
  }>(QuestionPageQuery, {
    skip: id === undefined,
    variables: { id },
  });

  const [deleteQuestion] = useMutation(DeleteQuestion, {
    variables: { id },
    onCompleted: () => history.goBack(),
  });

  const isMine = data && data?.question.user.id === me?.id;

  if (!id) return <NotFoundPage />;
  if (loading || !me) return <LoadingScreen />;
  if (error || !data) return <ErrorPage />;

  return (
    <>
      <TopNavbar title="Q&A" left={<BackButton />} />

      <Section>
        <div className="flex items-center space-x-4">
          <Avatar src={data.question.user.avatar} size={10} />
          <div className="flex-1 flex flex-col">
            <span className="font-bold text-sm">{data.question.user.name}</span>
            <time className="text-xs text-gray-400">
              {utcToLocalDatetime(data.question.createdAt)}
            </time>
          </div>
          <AnsweredIndicator answered={data.question.answered} />
        </div>
      </Section>

      {data.question.video ? (
        <ReactPlayer
          url={data.question.video}
          className="react-player"
          playing
          width="100%"
          height="100%"
          controls
        />
      ) : (
        <Divider />
      )}

      <Section>
        <h2 className="font-bold">{data.question.title}</h2>
        <p className="text-sm">{data.question.text}</p>

        {isMine && (
          <div className="flex justify-end space-x-1">
            <SmallTextButton
              variant="edit"
              onClick={() => history.push(`/questions/${id}/edit`)}
            />
            <SmallTextButton
              variant="delete"
              onClick={() => deleteQuestion()}
            />
          </div>
        )}
      </Section>

      <Divider />

      <Section>
        {data.question.comments.map((comment) => (
          <CommentListItem key={comment.id} comment={comment} />
        ))}
      </Section>

      {(isMine || me.role === Role.PRO) && (
        <Section>
          {commentEditing ? (
            <CommentEditor
              questionId={id}
              onFinished={() => setCommentEditing(false)}
            />
          ) : (
            <button onClick={() => setCommentEditing(true)}>
              <TextField placeholder="댓글을 입력하세요" />
            </button>
          )}
        </Section>
      )}
    </>
  );
};
