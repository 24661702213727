import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { NotificationCode } from './types';

const resources = {
  'ko-KR': {
    translation: {
      [NotificationCode.QUESTION_ANSWERED]: {
        title: '답변 알림',
        text: '내가 작성한 Q&A에 답변이 달렸습니다.',
      },
      [NotificationCode.RESERVATION_MADE]: {
        title: '예약 완료',
        text: '예약이 완료되었습니다.',
      },
      [NotificationCode.RESERVATION_CANCELED]: {
        title: '예약 취소',
        text: '예약이 취소되었습니다.',
      },
      [NotificationCode.A_DAY_BEFORE_ROOM]: {
        title: '예약 하루 전',
      },
      [NotificationCode.FIVE_MINUTES_BEFORE_LIVE_USER]: {
        title: '라이브 코칭 시간 5분전',
        text: '미리 입장하여 프로님과의 코칭 클래스를 준비해주세요.',
      },
      [NotificationCode.FIVE_MINUTES_BEFORE_LIVE_PRO]: {
        title: '라이브 코칭 시간 5분전',
        text: '미리 입장하여 회원님과의 코칭 클래스를 준비해주세요.',
      },
      [NotificationCode.FIVE_MINUTES_BEFORE_CALL_USER]: {
        title: '전화레슨 시간 5분전',
        text: '프로님과의 코칭 클래스를 준비해주세요.',
      },
      [NotificationCode.FIVE_MINUTES_BEFORE_CALL_PRO]: {
        title: '전화레슨 시간 5분전',
        text: '회원님과의 코칭 클래스를 준비해주세요.',
      },
    },
  },
};

i18next.use(initReactI18next).init({
  resources,
  fallbackLng: 'ko-KR',
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
});

export default i18next;
