import React, { FC } from 'react';

interface AnsweredIndicatorProps {
  answered: boolean;
}

export const AnsweredIndicator: FC<AnsweredIndicatorProps> = ({ answered }) => {
  return answered ? (
    <span className="font-bold text-xs text-brand-2">답변 완료</span>
  ) : (
    <span className="font-bold text-xs text-gray-400">대기중</span>
  );
};
