import {
  AuthRoute,
  gql,
  useApolloClient,
  useAuth,
  useRecoilState,
} from '@entropyparadox/reusable-react';
import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { GlobalAlert } from './components/GlobalAlert';
import './i18n';
import { ReviewAddPage } from './pages/ReviewAddPage';
import { CallPage } from './pages/CallPage';
import { EmailLoginPage } from './pages/EmailLoginPage';
import { LivePage } from './pages/LivePage';
import { MainPage } from './pages/MainPage';
import { MeEditPage } from './pages/MeEditPage';
import { NoticePage } from './pages/NoticePage';
import { NoticesPage } from './pages/NoticesPage';
import { NotificationsPage } from './pages/NotificationsPage';
import { PasswordResetPage } from './pages/PasswordResetPage';
import { PricingEditPage } from './pages/PricingEditPage';
import { ProPage } from './pages/ProPage';
import { ProVideoPage } from './pages/ProVideoPage';
import { QuestionEditPage } from './pages/QuestionEditPage';
import { QuestionPage } from './pages/QuestionPage';
import { ReservationAddPage } from './pages/ReservationAddPage';
import { ReservationPage } from './pages/ReservationPage';
import { ReviewEditPage } from './pages/ReviewEditPage';
import { SearchPage } from './pages/SearchPage';
import { SignupFormPage } from './pages/SignupFormPage';
import { SignupPage } from './pages/SignupPage';
import { TagSearchPage } from './pages/TagSearchPage';
import { TagsPage } from './pages/TagsPage';
import { TimeslotsEditPage } from './pages/TimeslotsEditPage';
import { meState } from './store';
import { Bridge } from './bridge';
import { SettlementPage } from './pages/SettlementPage';

const MeQuery = gql`
  query Me {
    me {
      id
      createdAt
      updatedAt
      email
      kakaoId
      appleId
      role
      name
      gender
      introduction
      avatar
      career
      callPrice
      livePrice
      exposed
      recommended
      title
      memberships {
        id
        branchId
      }
    }
  }
`;

const RegisterFcmToken = gql`
  mutation RegisterFcmToken($fcmToken: String!) {
    registerFcmToken(fcmToken: $fcmToken)
  }
`;

function App() {
  const client = useApolloClient();
  const { authenticated } = useAuth();
  const [me, setMe] = useRecoilState(meState);

  useEffect(() => {
    if (!client) return;
    if (authenticated && !me) {
      client
        .query({ query: MeQuery, fetchPolicy: 'network-only' })
        .then(({ data: { me } }) => setMe(me))
        .catch((error) => console.error(error));
    } else if (!authenticated && me) {
      setMe(undefined);
      client.clearStore();
    }
  }, [client, authenticated, me, setMe]);

  useEffect(() => {
    if (!client) return;
    if (!authenticated) return;
    Bridge.onGetFcmToken = ({ fcmToken }: any) => {
      if (!fcmToken) return;
      client.mutate({ mutation: RegisterFcmToken, variables: { fcmToken } });
    };
    Bridge.postMessage('getFcmToken');
  }, [client, authenticated]);

  return (
    <div className="max-w-md mx-auto">
      <BrowserRouter>
        <Switch>
          <AuthRoute path="/email-login" component={EmailLoginPage} guestOnly />
          <AuthRoute path="/signup-form" component={SignupFormPage} guestOnly />
          <AuthRoute path="/signup" component={SignupPage} guestOnly />
          <AuthRoute
            path="/password-reset"
            component={PasswordResetPage}
            guestOnly
          />
          <AuthRoute path="/reviews/:id/edit" component={ReviewEditPage} />
          <AuthRoute path="/pros/:id/add-review" component={ReviewAddPage} />
          <AuthRoute path="/notifications" component={NotificationsPage} />
          <AuthRoute path="/search" component={SearchPage} />
          <AuthRoute path="/tags" component={TagsPage} />
          <AuthRoute path="/tag-search" component={TagSearchPage} />
          <AuthRoute path="/pros/:id/edit" component={PricingEditPage} />
          <AuthRoute path="/pros/:id" component={ProPage} />
          <AuthRoute path="/pro-videos/:id" component={ProVideoPage} />
          <AuthRoute path="/questions/new" component={QuestionEditPage} />
          <AuthRoute path="/questions/:id/edit" component={QuestionEditPage} />
          <AuthRoute path="/questions/:id" component={QuestionPage} />
          <AuthRoute path="/reservations/new" component={ReservationAddPage} />
          <AuthRoute path="/reservations/:id" component={ReservationPage} />
          <AuthRoute path="/call/:id" component={CallPage} />
          <AuthRoute path="/live/:id" component={LivePage} />
          <AuthRoute path="/me/edit" component={MeEditPage} />
          <AuthRoute path="/timetable/edit" component={TimeslotsEditPage} />
          <AuthRoute path="/pricing/edit" component={PricingEditPage} />
          <AuthRoute path="/notices/:id" component={NoticePage} />
          <AuthRoute path="/notices" component={NoticesPage} />
          <AuthRoute path="/settlement" component={SettlementPage} />
          <Route path="/" component={MainPage} />
        </Switch>
        <GlobalAlert />
      </BrowserRouter>
    </div>
  );
}

export default App;
