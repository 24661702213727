import {
  AuthRoute,
  BottomNavbar,
  BottomNavbarItem,
  useAuth,
} from '@entropyparadox/reusable-react';
import React from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { ReactComponent as HomeIcon } from '../assets/images/icon-bottom-navbar-home.svg';
import { ReactComponent as MypageIcon } from '../assets/images/icon-bottom-navbar-mypage.svg';
import { ReactComponent as RoomIcon } from '../assets/images/icon-bottom-navbar-room.svg';
import { ReactComponent as ScheduleIcon } from '../assets/images/icon-bottom-navbar-schedule.svg';
import { ReactComponent as VideoIcon } from '../assets/images/icon-bottom-navbar-video.svg';
import { HomePage } from './HomePage';
import { LoginPage } from './LoginPage';
import { MyPage } from './MyPage';
import { QuestionsPage } from './QuestionsPage';
import { RoomsPage } from './RoomsPage';
import { SchedulePage } from './SchedulePage';
import { ReservationSavedPage } from './ReservationSavedPage';

export const MainPage = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { authenticated } = useAuth();

  return (
    <>
      <Switch>
        <Route path="/home" component={HomePage} />
        <AuthRoute path="/questions" component={QuestionsPage} />
        <AuthRoute path="/schedule" component={SchedulePage} />
        {process.env.REACT_APP_HIDE_MENU && (
          <AuthRoute path="/rooms" component={RoomsPage} />
        )}
        <AuthRoute path="/mypage" component={MyPage} />
        <AuthRoute path="/login" component={LoginPage} guestOnly />
        <AuthRoute path="/reservation-saved" component={ReservationSavedPage} />
        <Route path="/">
          <Redirect
            to={process.env.REACT_APP_HIDE_MENU ? '/schedule' : '/home'}
          />
        </Route>
      </Switch>

      <BottomNavbar>
        {!process.env.REACT_APP_HIDE_MENU && (
          <>
            <BottomNavbarItem
              icon={HomeIcon}
              label="홈"
              twIconActive={{ color: 'text-brand-2' }}
              twLabelActive={{ color: 'text-brand-2' }}
              active={pathname === '/home'}
              onClick={() => history.push('/home')}
            />
            <BottomNavbarItem
              icon={VideoIcon}
              label="영상 Q&A"
              twIconActive={{ color: 'text-brand-2' }}
              twLabelActive={{ color: 'text-brand-2' }}
              active={pathname === '/questions'}
              onClick={() => history.push('/questions')}
            />
          </>
        )}
        <BottomNavbarItem
          icon={ScheduleIcon}
          label="내 일정"
          twIconActive={{ color: 'text-brand-2' }}
          twLabelActive={{ color: 'text-brand-2' }}
          active={pathname === '/schedule'}
          onClick={() => history.push('/schedule')}
        />

        {process.env.REACT_APP_HIDE_MENU && (
          <BottomNavbarItem
            icon={RoomIcon}
            label="룸 예약"
            twIconActive={{ color: 'text-brand-2' }}
            twLabelActive={{ color: 'text-brand-2' }}
            active={pathname === '/rooms'}
            onClick={() => history.push('/rooms')}
          />
        )}

        <BottomNavbarItem
          icon={MypageIcon}
          label={authenticated ? '마이페이지' : '로그인'}
          twIconActive={{ color: 'text-brand-2' }}
          twLabelActive={{ color: 'text-brand-2' }}
          active={pathname === '/mypage' || pathname === '/login'}
          onClick={() => history.push(authenticated ? '/mypage' : '/login')}
        />
      </BottomNavbar>
    </>
  );
};
