import { Button, Screen, useQueryParams } from '@entropyparadox/reusable-react';
import React from 'react';

export const ReservationSavedPage = () => {
  const { result } = useQueryParams();
  let message = '';
  if (result === 'made') {
    message = '예약이 완료되었습니다.';
  }
  if (result === 'canceled') {
    message = '예약이 취소되었습니다.';
  }

  return (
    <Screen bottom>
      <div className="flex-1 flex flex-col justify-center items-center">
        <h2 className="font-bold text-xl">{message}</h2>
        <p className="mt-2 text-13 text-gray-500">예약 내역에서 확인하세요.</p>
        <div className="mt-12 w-60 flex flex-col">
          <Button text="내 일정 보러가기" to="/schedule" />
        </div>
      </div>
    </Screen>
  );
};
