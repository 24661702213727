import { Section } from '@entropyparadox/reusable-react';
import React from 'react';

export const NoSearchResult = () => {
  return (
    <Section>
      <div className="flex flex-col justify-center items-center space-y-1 h-40">
        <p className="font-bold text-xl">검색결과 없음</p>
        <p className="text-sm text-gray-500">
          필터 및 검색을 통해 원하는 분야를 검색해보세요.
        </p>
      </div>
    </Section>
  );
};
