import {
  BackButton,
  Background,
  gql,
  List,
  LoadingScreen,
  Tab,
  Tabs,
  TopNavbar,
  useQuery,
} from '@entropyparadox/reusable-react';
import React, { useState } from 'react';
import { NotificationListItem } from '../components';
import { Notification } from '../types';
import { ErrorPage } from './ErrorPage';

const NotificationsPageQuery = gql`
  query NotificationsPage {
    myQuestionNotifications {
      id
      createdAt
      code
      targetType
      targetId
    }
    myReservationNotifications {
      id
      createdAt
      code
      targetType
      targetId
    }
  }
`;

export const NotificationsPage = () => {
  const { loading, error, data } = useQuery<{
    myQuestionNotifications: Notification[];
    myReservationNotifications: Notification[];
  }>(NotificationsPageQuery);
  const [tabIndex, setTabIndex] = useState(0);

  if (loading) return <LoadingScreen />;
  if (error || !data) return <ErrorPage />;
  return (
    <>
      <Background tw={{ backgroundColor: 'bg-gray-100' }} />

      <TopNavbar title="알림" left={<BackButton />} borderless />

      <Tabs sticky>
        <Tab
          text="예약"
          full
          twInnerActive={{
            borderColor: 'border-brand-2',
            color: 'text-brand-2',
          }}
          active={tabIndex === 0}
          onClick={() => setTabIndex(0)}
        />
        <Tab
          text="Q&A"
          full
          twInnerActive={{
            borderColor: 'border-brand-2',
            color: 'text-brand-2',
          }}
          active={tabIndex === 1}
          onClick={() => setTabIndex(1)}
        />
      </Tabs>

      {tabIndex === 0 && (
        <List>
          {data.myReservationNotifications.map((notification) => (
            <NotificationListItem
              key={notification.id}
              notification={notification}
            />
          ))}
        </List>
      )}
      {tabIndex === 1 && (
        <List>
          {data.myQuestionNotifications.map((notification) => (
            <NotificationListItem
              key={notification.id}
              notification={notification}
            />
          ))}
        </List>
      )}
    </>
  );
};
