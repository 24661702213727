import {
  Background,
  gql,
  LoadingScreen,
  NotificationButton,
  Section,
  Tab,
  Tabs,
  TopNavbar,
  useQuery,
  useRecoilValue,
  utcToLocalFormat,
} from '@entropyparadox/reusable-react';
import React, { useState } from 'react';
import { ReservationCard } from '../components';
import { ErrorPage } from './ErrorPage';
import { Reservation } from '../types';
import { meState } from '../store';
import ChannelService from '../ChannelService';

const reservationFields = `
id
targetType
type
startedAt
endedAt
state
user {
  id
  name
  avatar
}
pro {
  id
  name
  avatar
}
room {
  id
  name
  branch {
    id
    name
  }
}
`;

const SchedulePageQuery = gql`
  query SchedulePage {
    myMadeReservations {
      ${reservationFields}
    }
    myPreviousReservations {
      ${reservationFields}
    }
    myCanceledReservations {
      ${reservationFields}
    }
  }
`;

export const SchedulePage = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const me = useRecoilValue(meState);
  const { loading, error, data } = useQuery<{
    myMadeReservations: Reservation[];
    myPreviousReservations: Reservation[];
    myCanceledReservations: Reservation[];
  }>(SchedulePageQuery);

  if (!me) return <LoadingScreen />;

  if (loading) return <LoadingScreen />;
  if (error || !data) return <ErrorPage />;

  ChannelService.boot({
    pluginKey: process.env.REACT_APP_CANNELIO_KEY,
    hideChannelButtonOnBoot: true,
    mobileMessengerMode: 'iframe',
    profile: {
      name: me.name,
      profileMobileNumber: me.phone,
      avatarUrl: me.avatar,
      profileEmail: me.email,
      member: me.memberships.length > 0 ? true : false,
      memberId: me.id,
    },
    customLauncherSelector: '#cannelIo-button1',
  });

  return (
    <>
      <Background tw={{ backgroundColor: 'bg-gray-100' }} />

      <TopNavbar title="내 일정" left={<NotificationButton />} borderless />

      <Tabs sticky>
        <Tab
          text="예정"
          full
          twInnerActive={{
            borderColor: 'border-brand-2',
            color: 'text-brand-2',
          }}
          active={tabIndex === 0}
          onClick={() => setTabIndex(0)}
        ></Tab>
        <Tab
          text="완료"
          full
          twInnerActive={{
            borderColor: 'border-brand-2',
            color: 'text-brand-2',
          }}
          active={tabIndex === 1}
          onClick={() => setTabIndex(1)}
        ></Tab>
        <Tab
          text="취소"
          full
          twInnerActive={{
            borderColor: 'border-brand-2',
            color: 'text-brand-2',
          }}
          active={tabIndex === 2}
          onClick={() => setTabIndex(2)}
        ></Tab>
      </Tabs>

      {tabIndex === 0 && (
        <Section>
          <div className="text-13 leading-5">
            <p>
              *예약취소 관련 내용은{' '}
              <span
                id="cannelIo-button1"
                className="text-brand-2 cursor-pointer"
              >
                문의하기
              </span>
              를 눌러주세요.
            </p>
            <p>*예약취소는 하루전까지 가능합니다.</p>
          </div>
        </Section>
      )}

      {tabIndex === 0 &&
        Array.from(
          data.myMadeReservations.reduce((reservationGroups, reservation) => {
            const date = utcToLocalFormat(reservation.startedAt, 'LL (dd)');
            reservationGroups.set(date, [
              ...(reservationGroups.get(date) ?? []),
              reservation,
            ]);
            return reservationGroups;
          }, new Map<string, Reservation[]>()),
        ).map(([date, reservationGroup]) => (
          <Section key={date}>
            <h3 className="font-bold">{date}</h3>
            {reservationGroup.map((reservation) => (
              <ReservationCard key={reservation.id} reservation={reservation} />
            ))}
          </Section>
        ))}

      {tabIndex === 1 &&
        Array.from(
          data.myPreviousReservations.reduce(
            (reservationGroups, reservation) => {
              const date = utcToLocalFormat(reservation.startedAt, 'LL (dd)');
              reservationGroups.set(date, [
                ...(reservationGroups.get(date) ?? []),
                reservation,
              ]);
              return reservationGroups;
            },
            new Map<string, Reservation[]>(),
          ),
        ).map(([date, reservationGroup]) => (
          <Section key={date}>
            <h3 className="font-bold">{date}</h3>
            {reservationGroup.map((reservation) => (
              <ReservationCard key={reservation.id} reservation={reservation} />
            ))}
          </Section>
        ))}

      {tabIndex === 2 &&
        Array.from(
          data.myCanceledReservations.reduce(
            (reservationGroups, reservation) => {
              const date = utcToLocalFormat(reservation.startedAt, 'LL (dd)');
              reservationGroups.set(date, [
                ...(reservationGroups.get(date) ?? []),
                reservation,
              ]);
              return reservationGroups;
            },
            new Map<string, Reservation[]>(),
          ),
        ).map(([date, reservationGroup]) => (
          <Section key={date}>
            <h3 className="font-bold">{date}</h3>
            {reservationGroup.map((reservation) => (
              <ReservationCard key={reservation.id} reservation={reservation} />
            ))}
          </Section>
        ))}
    </>
  );
};
