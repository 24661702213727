export interface Category {
  id: number;
  createdAt: string;
  updatedAt: string;
  name: string;
  tags: Tag[];
}

export interface Compliment {
  id: number;
  createdAt: string;
  updatedAt: string;
  text: string;
}

export interface PopUp {
  id: number;
  createdAt: string;
  updatedAt: string;
  thumbnail: string;
  link: string;
}

export interface Review {
  id: number;
  proId: number;
  content: string;
  createdAt: string;
  updatedAt: string;
  compliments: Compliment[];
  user: User;
  pro?: User;
}
export interface Payment {
  id: number;
  card_name: string;
  card_no: string;
  status: Status;
  finalPrice: number;
  createdAt: string;
  updatedAt: string;
}
export interface Branch {
  id: number;
  createdAt: string;
  updatedAt: string;
  name: string;
  remarks: string;
  rooms: Room[];
}

export interface Comment {
  id: number;
  createdAt: string;
  updatedAt: string;
  text: string;
  questionId: number;
  user: User;
}

export interface Membership {
  id: number;
  createdAt: string;
  updatedAt: string;
  branchId: number;
  user: User;
  branch: Branch;
}

export interface Notice {
  id: number;
  createdAt: string;
  updatedAt: string;
  title: string;
  text: string;
  image: string;
}

export interface Banners {
  id: number;
  createdAt: string;
  updatedAt: string;
  link?: string;
  text?: string;
  exposureRank: number;
  image?: string;
  exposed: boolean;
}
export interface Notification {
  id: number;
  createdAt: string;
  updatedAt: string;
  code: NotificationCode;
  targetType: NotificationTargetType;
  targetId: number;
}

export enum NotificationCode {
  QUESTION_ANSWERED = 'QUESTION_ANSWERED',
  RESERVATION_MADE = 'RESERVATION_MADE',
  RESERVATION_CANCELED = 'RESERVATION_CANCELED',
  A_DAY_BEFORE_ROOM = 'A_DAY_BEFORE_ROOM',
  FIVE_MINUTES_BEFORE_LIVE_USER = 'FIVE_MINUTES_BEFORE_LIVE_USER',
  FIVE_MINUTES_BEFORE_LIVE_PRO = 'FIVE_MINUTES_BEFORE_LIVE_PRO',
  FIVE_MINUTES_BEFORE_CALL_USER = 'FIVE_MINUTES_BEFORE_CALL_USER',
  FIVE_MINUTES_BEFORE_CALL_PRO = 'FIVE_MINUTES_BEFORE_CALL_PRO',
}

export enum NotificationTargetType {
  QUESTION = 'QUESTION',
  RESERVATION = 'RESERVATION',
}

export interface ProVideo {
  id: number;
  createdAt: string;
  updatedAt: string;
  video: string;
  user: User;
  tag: Tag;
}

export interface Question {
  id: number;
  createdAt: string;
  updatedAt: string;
  title: string;
  text: string;
  video: string;
  answered: boolean;
  user: User;
  comments: Comment[];
}

export interface Reservation {
  id: number;
  createdAt: string;
  updatedAt: string;
  targetType: ReservationTargetType;
  targetId: number;
  type: ReservationType;
  startedAt: string;
  endedAt: string;
  note: string;
  state: ReservationState;
  user: User;
  pro: User;
  room: Room;
  payment: Payment;
}

export enum ReservationType {
  CALL = 'CALL',
  LIVE = 'LIVE',
  ROOM = 'ROOM',
}

export enum ReservationState {
  MADE = 'MADE',
  CANCELED = 'CANCELED',
}

export enum ReservationTargetType {
  USER = 'USER',
  ROOM = 'ROOM',
}

export enum Status {
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
  REFUND = 'REFUND',
}
export interface Room {
  id: number;
  createdAt: string;
  updatedAt: string;
  name: string;
  branch: Branch;
  timeslots: Timeslot[];
  reservations: Reservation[];
}

export interface Tag {
  id: number;
  createdAt: string;
  updatedAt: string;
  name: string;
}

export interface User {
  id: number;
  createdAt: string;
  updatedAt: string;
  email: string;
  kakaoId: number;
  appleId: number;
  name: string;
  phone: string;
  role: Role;
  gender: Gender;
  introduction: string;
  avatar: string;
  career: string;
  callPrice: number;
  exposureRank: number;
  livePrice: number;
  exposed: boolean;
  recommended: boolean;
  proVideos: ProVideo[];
  memberships: Membership[];
  timeslots: Timeslot[];
  reservations: Reservation[];
  review?: Review;
  canReview?: boolean;
  payment?: Payment;
  title: string;
}

export enum Role {
  ADMIN = 'ADMIN',
  PRO = 'PRO',
  USER = 'USER',
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export interface Timeslot {
  id: number;
  createdAt: string;
  updatedAt: string;
  targetType: ReservationTargetType;
  targetId: number;
  reservationType: ReservationType;
  startedAt: string;
  endedAt: string;
  open: boolean;
  isFallback: boolean;
}

export interface AgoraResponse {
  token: string;
}
