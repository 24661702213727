import { osState } from './plugins/ridge';

type Action =
  | 'log'
  | 'error'
  | 'loginWithKakao'
  | 'loginWithApple'
  | 'getFcmToken'
  | 'startCall'
  | 'startLive';

interface Command {
  kind: 'WebView';
  action: Exclude<
    keyof typeof Bridge,
    'prototype' | 'os' | 'postMessage' | 'handleMessageEvent'
  >;
  value?: any;
}

function tryParseJSON(jsonString: any) {
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    return null;
  }
}

export class Bridge {
  static postMessage(action: Action, value?: any) {
    const data = JSON.stringify({ action, value });
    (window as any).ReactNativeWebView?.postMessage(data);
  }

  static handleMessageEvent({ data }: MessageEvent) {
    const command = tryParseJSON(data) as Command;
    if (command?.kind !== 'WebView') return;
    if (Bridge[command.action]) {
      Bridge[command.action](command.value);
    } else {
      Bridge.postMessage('error', `(RN -> WebView) Invalid action: ${data}`);
    }
  }

  static init({ os }: any) {
    osState.set(os ?? 'webview');
  }

  static onLoginWithKakao(value: any) {
    Bridge.postMessage('log', value);
  }

  static onLoginWithApple(value: any) {
    Bridge.postMessage('log', value);
  }

  static onGetFcmToken(value: any) {
    Bridge.postMessage('log', value);
  }
}

// @ts-ignore
document.addEventListener('message', Bridge.handleMessageEvent);
window.addEventListener('message', Bridge.handleMessageEvent);
