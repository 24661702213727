import {
  BackButton,
  BottomFixedButton,
  BoxTab,
  FlexWrap,
  gql,
  LoadingScreen,
  Section,
  StickyToTop,
  Tabs,
  TopNavbar,
  useMutation,
  useQuery,
  utcToLocalFormat,
} from '@entropyparadox/reusable-react';
import moment from 'moment';
import React, { useState } from 'react';
import { CheckButton, DateSelect } from '../components';
import { ReservationType, Timeslot } from '../types';
import { ErrorPage } from './ErrorPage';

const TimeslotsEditPageQuery = gql`
  query TimeslotsEditPage(
    $reservationType: ReservationType!
    $startOfDate: DateTime!
  ) {
    myTimeslots(reservationType: $reservationType, startOfDate: $startOfDate) {
      id
      startedAt
      endedAt
      open
      isFallback
    }
  }
`;

const WriteMyTimeslots = gql`
  mutation WriteMyTimeslots(
    $reservationType: ReservationType!
    $startOfDate: DateTime!
    $timeslotIds: [Int!]!
  ) {
    writeMyTimeslots(
      reservationType: $reservationType
      startOfDate: $startOfDate
      timeslotIds: $timeslotIds
    ) {
      ids
    }
  }
`;

export const TimeslotsEditPage = () => {
  const [reservationType, setReservationType] = useState(ReservationType.CALL);
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [timeslotIds, setTimeslotIds] = useState([] as number[]);
  const [modified, setModified] = useState(false);

  const { loading, error, data } = useQuery<{ myTimeslots: Timeslot[] }>(
    TimeslotsEditPageQuery,
    {
      variables: { reservationType, startOfDate: moment(date).toISOString() },
      onCompleted: ({ myTimeslots }) => {
        setTimeslotIds(
          myTimeslots.reduce((ids, timeslot) => {
            if (timeslot.isFallback || !timeslot.open) {
              return ids;
            }
            const id = myTimeslots.find(
              (t) =>
                t.isFallback &&
                moment(t.startedAt).format('HHmmss') ===
                  moment(timeslot.startedAt).format('HHmmss'),
            )?.id;
            if (id) {
              ids.push(id);
            }
            return ids;
          }, [] as number[]),
        );
      },
    },
  );

  const [writeMyTimeslots] = useMutation(WriteMyTimeslots, {
    variables: {
      reservationType,
      startOfDate: moment(date).toISOString(),
      timeslotIds,
    },
    refetchQueries: [
      {
        query: TimeslotsEditPageQuery,
        variables: { reservationType, startOfDate: moment(date).toISOString() },
      },
    ],
    onCompleted: () => {
      setModified(false);
    },
  });

  if (loading) return <LoadingScreen />;
  if (error || !data) return <ErrorPage />;
  return (
    <>
      <TopNavbar title="스케줄 설정" left={<BackButton />} borderless />

      <StickyToTop>
        <Tabs tw={{ borderWidth: 'border-0', height: 'h-16' }}>
          <BoxTab
            text="전화 레슨"
            active={reservationType === ReservationType.CALL}
            onClick={() => {
              setReservationType(ReservationType.CALL);
              setModified(false);
            }}
          />
          <BoxTab
            text="원격 라이브 레슨"
            active={reservationType === ReservationType.LIVE}
            onClick={() => {
              setReservationType(ReservationType.LIVE);
              setModified(false);
            }}
          />
        </Tabs>

        <Section>
          <DateSelect
            value={date}
            onChange={(date) => {
              setDate(date);
              setModified(false);
            }}
          />
        </Section>
      </StickyToTop>

      <Section>
        <p className="text-xs text-gray-400">
          *날짜별 활동 가능한 시간에 모두 체크해주세요.
          <br />
          체크된 시간은 일반 유저가 레슨 예약이 가능하게 오픈됩니다.
        </p>
      </Section>

      <Section>
        <FlexWrap>
          {data.myTimeslots
            .filter((timeslot) => timeslot.isFallback)
            .sort(
              (a, b) =>
                new Date(a.startedAt).getHours() -
                new Date(b.startedAt).getHours(),
            )
            .map((timeslot) => {
              const active = timeslotIds.includes(timeslot.id);

              return (
                <CheckButton
                  key={timeslot.id}
                  active={active}
                  onClick={() => {
                    setTimeslotIds(
                      active
                        ? timeslotIds.filter((id) => id !== timeslot.id)
                        : timeslotIds.concat(timeslot.id),
                    );
                    setModified(true);
                  }}
                >
                  {utcToLocalFormat(timeslot.startedAt, 'a')}
                  <br />
                  {utcToLocalFormat(timeslot.startedAt, 'h:mm')}
                </CheckButton>
              );
            })}
        </FlexWrap>
      </Section>

      <BottomFixedButton
        tw={{ height: 'h-16' }}
        text="수정 완료"
        disabled={!modified}
        onClick={() => writeMyTimeslots()}
      />
    </>
  );
};
