import {
  BackButton,
  BottomFixedButton,
  Chip,
  Divider,
  FlexWrap,
  gql,
  LoadingScreen,
  Section,
  TopNavbar,
  useQuery,
} from '@entropyparadox/reusable-react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Category } from '../types';
import { ErrorPage } from './ErrorPage';

const TagsPageQuery = gql`
  query TagsPage {
    categories {
      id
      name
      tags {
        id
        name
      }
    }
  }
`;

export const TagsPage = () => {
  const history = useHistory();
  const [selectedTagIds, setSelectedTagIds] = useState(new Set<number>());
  const { loading, error, data } =
    useQuery<{
      categories: Category[];
    }>(TagsPageQuery);

  if (loading) return <LoadingScreen />;
  if (error || !data) return <ErrorPage />;
  return (
    <>
      <TopNavbar title="분야 선택" left={<BackButton />} borderless />

      {data.categories.map((category, index) => (
        <React.Fragment key={category.id}>
          <Section>
            <h3>{category.name}</h3>
            <FlexWrap>
              {category.tags.map((tag) => (
                <Chip
                  key={tag.id}
                  text={tag.name}
                  active={selectedTagIds.has(tag.id)}
                  onClick={() => {
                    const newValue = new Set(selectedTagIds);
                    if (selectedTagIds.has(tag.id)) {
                      newValue.delete(tag.id);
                    } else {
                      newValue.add(tag.id);
                    }
                    setSelectedTagIds(newValue);
                  }}
                />
              ))}
            </FlexWrap>
          </Section>
          {index < data.categories.length - 1 && (
            <Divider tw={{ marginX: 'mx-5' }} />
          )}
        </React.Fragment>
      ))}

      <BottomFixedButton
        tw={{ height: 'h-16' }}
        text="선택 완료"
        disabled={selectedTagIds.size === 0}
        onClick={() =>
          history.replace(
            `/tag-search?ids=${Array.from(selectedTagIds).join()}`,
          )
        }
      />
    </>
  );
};
