import {
  Avatar,
  Button,
  CloseIcon,
  gql,
  IconButton,
  LoadingScreen,
  TopNavbar,
  useIdParam,
  useQuery,
  useRecoilValue,
  useShowAlert,
  utcToLocalTime,
} from '@entropyparadox/reusable-react';
import AgoraRTC, {
  IAgoraRTCClient,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
} from 'agora-rtc-sdk-ng';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { meState } from '../store';
import { Reservation, AgoraResponse } from '../types';
import { ErrorPage } from './ErrorPage';
import { NotFoundPage } from './NotFoundPage';

const APP_ID = 'ec3a3424ae1544c483a1952410c8c4f7';

const LivePageQuery = gql`
  query LivePage($id: Int!) {
    reservation(id: $id) {
      id
      startedAt
      endedAt
      user {
        id
        name
        avatar
      }
      pro {
        id
        name
        avatar
      }
    }
    agoraToken(id: $id) {
      token
    }
  }
`;

export const LivePage = () => {
  const id = useIdParam();
  const history = useHistory();
  const showAlert = useShowAlert();
  const me = useRecoilValue(meState);
  const [client, setClient] = useState<IAgoraRTCClient>();
  const [token, setToken] = useState('');
  const [remoteCount, setRemoteCount] = useState(0);
  const remote = useRef<HTMLDivElement>(null);
  const [localTracks, setLocalTracks] = useState<
    [IMicrophoneAudioTrack, ICameraVideoTrack]
  >();

  useEffect(() => {
    if (client) return;
    const c = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });
    c.on('user-published', async (user, mediaType) => {
      await c.subscribe(user, mediaType);
      user.videoTrack?.play('remote');
      user.audioTrack?.play();
      setRemoteCount((count) => count + 1);
    });
    c.on('user-unpublished', () => setRemoteCount((count) => count - 1));
    setClient(c);
  }, [client]);

  useEffect(() => {
    if (localTracks) return () => localTracks.forEach((track) => track.close());
    AgoraRTC.createMicrophoneAndCameraTracks().then((tracks) => {
      tracks[1].play('local');
      setLocalTracks(tracks);
    });
  }, [localTracks]);

  useEffect(() => {
    if (!id || !me || !client || !localTracks || !token) return;
    client
      .join(APP_ID, id.toString(), token, me.id)
      .then(() => client.publish(localTracks));
    return () => {
      client.leave();
    };
  }, [id, me, client, localTracks, token]);

  const { loading, error, data } = useQuery<{
    reservation: Reservation;
    agoraToken: AgoraResponse;
  }>(LivePageQuery, {
    skip: id === undefined,
    variables: { id },
    onCompleted: ({ agoraToken }) => setToken(agoraToken.token),
  });

  useEffect(() => {
    if (!data) return;
    if (remoteCount === 0) return;
    const interval = setInterval(() => {
      const endedAt = moment(data.reservation.endedAt);
      if (endedAt.subtract(5 * 60, 's').isSame(moment(), 's')) {
        showAlert('info', '레슨시간이 5분 남았습니다.');
      } else if (endedAt.isSame(moment(), 's')) {
        showAlert('info', '레슨시간이 종료되었습니다.');
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [data, remoteCount]); // eslint-disable-line

  if (!id) return <NotFoundPage />;
  if (loading || !me) return <LoadingScreen />;
  if (error || !data) return <ErrorPage />;
  return (
    <>
      <div
        id="remote"
        ref={remote}
        className="fixed inset-0 -z-10"
        style={{
          background: 'linear-gradient(180deg, #536976 12.75%, #292E49 76.54%)',
        }}
      />

      {remoteCount === 0 && (
        <TopNavbar
          right={
            <IconButton
              icon={<CloseIcon className="stroke-current text-white" />}
              onClick={() => history.goBack()}
            />
          }
          position="fixed"
          borderless
          backgroundColor="transparent"
        />
      )}

      <div className="fixed inset-0 flex justify-center items-center">
        {remoteCount === 0 && (
          <div className="flex flex-col items-center space-y-2 text-white">
            <p className="text-xl">비대면 라이브 코칭 대기중</p>
            <p className="font-medium text-15">
              {utcToLocalTime(data.reservation.startedAt)} 시작
            </p>
            <div className="flex items-center space-x-3">
              <Avatar
                src={
                  data.reservation.user.id === me.id
                    ? data.reservation.pro.avatar
                    : data.reservation.user.avatar
                }
                size={8}
              />
              <span>
                {data.reservation.user.id === me.id
                  ? `${data.reservation.pro.name} 프로`
                  : `${data.reservation.user.name} 회원`}
              </span>
            </div>
          </div>
        )}
      </div>

      <div className="fixed inset-0 z-10 px-5 py-6">
        <div className="w-full h-full flex flex-col justify-end space-y-9">
          <div id="local" className="w-1/3 h-1/4" />
          {remoteCount > 0 && (
            <div className="flex justify-between">
              <div className="flex flex-col text-white">
                <p className="font-semibold text-15">
                  {data.reservation.user.id === me.id
                    ? `${data.reservation.pro.name} 프로`
                    : `${data.reservation.user.name} 회원`}
                </p>
                <p className="text-14">
                  {utcToLocalTime(data.reservation.endedAt)} 종료 예정
                </p>
              </div>
              <Button
                text="종료하기"
                tw={{ backgroundColor: 'bg-red-500', height: 'h-11' }}
                onClick={() =>
                  // eslint-disable-next-line
                  confirm(
                    '정말 종료하시겠습니까?\n확인을 누르면 코칭이 종료됩니다.',
                  ) && history.goBack()
                }
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
