import { BackButton, TopNavbar } from '@entropyparadox/reusable-react';
import React from 'react';

export const NotFoundPage = () => {
  return (
    <>
      <TopNavbar title="Not Found" left={<BackButton />} />

      <h2>항목을 찾을 수 없습니다.</h2>
    </>
  );
};
