import {
  Button,
  Divider,
  gql,
  List,
  LoadingScreen,
  NotificationButton,
  Section,
  Tab,
  Tabs,
  TopNavbar,
  useQuery,
  useRecoilValue,
} from '@entropyparadox/reusable-react';
import React, { useState } from 'react';
import { QuestionListItem } from '../components';
import { meState } from '../store';
import { Question, Role } from '../types';
import { ErrorPage } from './ErrorPage';

const questionFields = 'id createdAt title text answered user { id name }';

const QuestionsPageQuery = gql`
  query QuestionsPage {
    questions { ${questionFields} }
    answeredQuestions { ${questionFields} }
    unansweredQuestions { ${questionFields} }
    myQuestions { ${questionFields} }
    answeredQuestionsByMe { ${questionFields} }
  }
`;

export const QuestionsPage = () => {
  const me = useRecoilValue(meState);
  const { loading, error, data } = useQuery<{
    questions: Question[];
    answeredQuestions: Question[];
    unansweredQuestions: Question[];
    myQuestions: Question[];
    answeredQuestionsByMe: Question[];
  }>(QuestionsPageQuery);
  const [tabIndex, setTabIndex] = useState(0);

  if (!me) return <LoadingScreen />;
  if (loading) return <LoadingScreen />;
  if (error || !data) return <ErrorPage />;
  return (
    <>
      <TopNavbar
        title="Q&A"
        left={<NotificationButton />}
        borderless={me.role !== Role.USER}
      />

      {me.role === Role.USER && (
        <>
          <Section>
            <div
              className="flex flex-col justify-between space-y-7 pt-6 px-3 pb-3
                         rounded-lg bg-gray-100"
            >
              <p className="text-center">
                나의 스윙 영상을 올리고 무료로 프로의
                <br />
                피드백을 받아보세요!
              </p>
              <Button text="질문 남기기" to="/questions/new" />
            </div>
          </Section>
          <Divider tw={{ height: 'h-3' }} />
        </>
      )}

      <Tabs sticky tw={{ height: me.role === Role.USER ? 'h-16' : 'h-10' }}>
        <Tab
          text="전체"
          full
          twInnerActive={{
            borderColor: 'border-brand-2',
            color: 'text-brand-2',
          }}
          active={tabIndex === 0}
          onClick={() => setTabIndex(0)}
        />
        <Tab
          text={me.role === Role.USER ? '답변완료' : '대기중'}
          full
          twInnerActive={{
            borderColor: 'border-brand-2',
            color: 'text-brand-2',
          }}
          active={tabIndex === 1}
          onClick={() => setTabIndex(1)}
        />
        <Tab
          text={me.role === Role.USER ? '내 질문' : '내 답변'}
          full
          twInnerActive={{
            borderColor: 'border-brand-2',
            color: 'text-brand-2',
          }}
          active={tabIndex === 2}
          onClick={() => setTabIndex(2)}
        />
      </Tabs>

      <List>
        {tabIndex === 0 &&
          data.questions.map((question) => (
            <QuestionListItem key={question.id} question={question} />
          ))}

        {tabIndex === 1 &&
          (me.role === Role.PRO
            ? data.unansweredQuestions.map((question) => (
                <QuestionListItem key={question.id} question={question} />
              ))
            : data.answeredQuestions.map((question) => (
                <QuestionListItem key={question.id} question={question} />
              )))}

        {tabIndex === 2 &&
          (me.role === Role.PRO
            ? data.answeredQuestionsByMe.map((question) => (
                <QuestionListItem key={question.id} question={question} />
              ))
            : data.myQuestions.map((question) => (
                <QuestionListItem key={question.id} question={question} />
              )))}
      </List>
    </>
  );
};
