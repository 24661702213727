import {
  gql,
  LoadingScreen,
  NotificationButton,
  SearchButton,
  Section,
  TopNavbar,
  useQuery,
} from '@entropyparadox/reusable-react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ProCard, CategoryButton, CategoryButtons } from '../components';
import { Popup } from '../components/Pop-up';
import { Banners, Category, PopUp, Tag, User } from '../types';
import { ErrorPage } from './ErrorPage';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const HomePageQuery = gql`
  query HomePage {
    banners {
      id
      image
      link
      text
      exposureRank
    }
    categories {
      id
      name
    }
    recommendedTags {
      id
      name
    }
    recommendedPros {
      id
      name
      callPrice
      livePrice
      exposureRank
      avatar
    }
    popUps {
      id
      thumbnail
      link
    }
  }
`;

export const HomePage = () => {
  const history = useHistory();
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    const HAS_VISITED_BEFORE = localStorage.getItem('hasVisitedBefore');
    if (HAS_VISITED_BEFORE && Number(HAS_VISITED_BEFORE) > Date.now()) {
      return;
    }
    setIsShow(true);
  }, []);

  const { loading, error, data } = useQuery<{
    banners: Banners[];
    categories: Category[];
    recommendedTags: Tag[];
    recommendedPros: User[];
    popUps: PopUp[];
  }>(HomePageQuery);

  if (loading) return <LoadingScreen />;
  if (error || !data) return <ErrorPage />;

  const recommendedProsData = data.recommendedPros
    .slice()
    .sort(function (a, b) {
      return 0.5 - Math.random();
    })
    .sort((a, b) => {
      if (a.exposureRank < b.exposureRank) {
        return -1;
      }
      if (a.exposureRank === null || b.exposureRank === null) {
        return 1;
      }
      return 0;
    })
    .sort((a, b) => {
      if (a.exposureRank === null || b.exposureRank === null) {
        return -1;
      }
      return 0;
    });

  const bannersData = data.banners.slice().sort((a, b) => {
    if (a.exposureRank < b.exposureRank) {
      return -1;
    }
    if (a.exposureRank === null || b.exposureRank === null) {
      return 1;
    }
    return 0;
  });

  return (
    <>
      {data.popUps.length !== 0 && (
        <>
          {isShow && (
            <Popup
              popUp={data.popUps[0]}
              onDismiss={() => {
                let expires = Date.now();
                expires = expires + 60 * 60 * 24 * 1000; // 1 day
                localStorage.setItem('hasVisitedBefore', expires.toString());
                setIsShow(false);
              }}
              onClose={() => setIsShow(false)}
            />
          )}
        </>
      )}
      <TopNavbar
        title="The Six Studio"
        left={<NotificationButton />}
        right={<SearchButton />}
      />

      <Section>
        {data.banners.length !== 0 && (
          <Carousel
            showArrows={false}
            showStatus={false}
            showIndicators={false}
            showThumbs={false}
            interval={2500}
            autoPlay
            infiniteLoop
            emulateTouch
          >
            {bannersData.map((banner) => (
              <div
                key={banner.id}
                className="h-64 w-full mb-4 cursor-pointer"
                onClick={() => {
                  window.open(banner.link, '_blank');
                }}
              >
                <img
                  className="h-64 object-cover rounded-lg"
                  src={banner.image}
                  alt=""
                />
              </div>
            ))}
          </Carousel>
        )}

        <CategoryButtons>
          {data.categories.map((category) => (
            <CategoryButton
              key={category.id}
              category={category}
              onClick={() => history.push('/tags')}
            />
          ))}
        </CategoryButtons>
      </Section>

      {/* 
      <Section tw={{ backgroundColor: 'bg-gray-50' }}>
        <h3>추천 분석</h3>
        <FlexWrap>
          {data.recommendedTags.map((tag) => (
            <Chip
              key={tag.id}
              text={tag.name}
              onClick={() => history.push(`/tag-search?ids=${tag.id}`)}
            />
          ))}
        </FlexWrap>
      </Section> */}

      <Section>
        <h3>추천 프로</h3>
        <div className="grid grid-cols-2 gap-x-3 gap-y-6">
          {recommendedProsData.map((pro) => (
            <ProCard key={pro.id} pro={pro} />
          ))}
        </div>
      </Section>
    </>
  );
};
