import {
  Avatar,
  Badge,
  Button,
  gql,
  IconButton,
  LoadingScreen,
  tokenState,
  useQuery,
  useRecoilValue,
  utcToLocalTime,
} from '@entropyparadox/reusable-react';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as RightArrowIcon } from '../assets/images/icon-right-arrow.svg';
import { ErrorPage } from '../pages/ErrorPage';
import { Bridge } from '../bridge';
import { meState } from '../store';
import {
  Reservation,
  ReservationState,
  ReservationTargetType,
  ReservationType,
  Review,
  Role,
} from '../types';
import { osState } from '../plugins/ridge';
interface ReservationCardProps {
  reservation: Reservation;
  simple?: boolean;
}

const ReservationCardQuery = gql`
  query ReservationCardQuery($proId: Int) {
    reviewByUserIdAndProId(proId: $proId) {
      id
      proId
    }
  }
`;

export const ReservationCard: FC<ReservationCardProps> = ({
  reservation,
  simple = false,
}) => {
  const history = useHistory();
  const token = useRecoilValue(tokenState);
  const me = useRecoilValue(meState);
  const os = osState.useValue();
  const shadow = simple ? '' : 'shadow-md';
  // const opacity = state === 'DONE' ? 'opacity-50' : 'opacity-100';

  let badgeColor = 'bg-darkgreen-4';
  switch (reservation.type) {
    case ReservationType.CALL:
      badgeColor = 'bg-navy-5';
      break;
    case ReservationType.LIVE:
      badgeColor = 'bg-brand-2';
      break;
  }

  const { loading, error, data } = useQuery<{
    reviewByUserIdAndProId: Review;
  }>(ReservationCardQuery, {
    skip: reservation.type === ReservationType.ROOM,
    variables: { proId: reservation.pro?.id },
  });

  const now = Date.parse(new Date().toISOString());

  const startedAt = new Date(reservation.startedAt);
  const checkReservationTime = startedAt.setMinutes(
    startedAt.getMinutes() - 10,
  );
  const possibleTime = now > checkReservationTime;

  const endedAt = Date.parse(new Date(reservation.endedAt).toISOString());
  const finished = now > endedAt;

  if (!me) return <></>;
  if (loading) return <LoadingScreen />;
  if (error) return <ErrorPage />;

  return (
    <div
      className={`flex flex-col space-y-4 p-4 rounded-lg ${shadow} bg-white`}
      onClick={
        simple
          ? undefined
          : () => history.push(`/reservations/${reservation.id}`)
      }
    >
      <div className="flex justify-between items-center">
        {reservation.targetType === ReservationTargetType.USER && (
          <div className="mr-3">
            <Avatar
              src={
                reservation.user.id === me.id
                  ? reservation.pro.avatar
                  : reservation.user.avatar
              }
              size={18}
            />
          </div>
        )}
        <div className="flex-1">
          <div className="flex items-center space-x-2">
            <Badge
              text={reservation.type}
              tw={{ backgroundColor: badgeColor as any, color: 'text-white' }}
            />
            {reservation.state === ReservationState.CANCELED && (
              <span className="text-sm">취소됨</span>
            )}
          </div>
          <div className="font-bold mt-1 text-sm">
            {utcToLocalTime(reservation.startedAt)}-
            {utcToLocalTime(reservation.endedAt)}
          </div>
          <div className="font-bold text-13">
            {reservation.targetType === ReservationTargetType.USER
              ? reservation.user.id === me.id
                ? `${reservation.pro.name} 프로`
                : `${reservation.user.name} 회원`
              : `${reservation.room.branch.name} / ${reservation.room.name}`}
          </div>
        </div>
        {!simple && (
          <div className="-mr-2">
            <IconButton icon={<RightArrowIcon />} />
          </div>
        )}
      </div>

      {!simple &&
        reservation.state === ReservationState.MADE &&
        (!finished ? (
          <>
            {reservation.type === ReservationType.LIVE && (
              <Button
                tw={{
                  backgroundColor: 'bg-black',
                  fontSize: 'text-sm',
                  height: 'h-12',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (!possibleTime) {
                    return alert('아직 라이브레슨 시간이 아닙니다');
                  }
                  if (os) {
                    Bridge.postMessage('startLive', {
                      reservationId: reservation.id,
                      myId: me.id,
                      token,
                    });
                  } else {
                    history.push(`/live/${reservation.id}`);
                  }
                }}
              >
                라이브 클래스 입장
              </Button>
            )}
            {reservation.type === ReservationType.CALL && (
              <Button
                tw={{
                  backgroundColor: 'bg-black',
                  fontSize: 'text-sm',
                  height: 'h-12',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (!possibleTime) {
                    return alert('아직 전화 레슨 시간이 아닙니다');
                  }
                  if (os) {
                    Bridge.postMessage('startCall', {
                      reservationId: reservation.id,
                      myId: me.id,
                      token,
                    });
                  } else {
                    history.push(`/call/${reservation.id}`);
                  }
                }}
              >
                전화 레슨 입장
              </Button>
            )}
          </>
        ) : data?.reviewByUserIdAndProId && me.role === Role.USER ? (
          <Button
            variant="outlined"
            tw={{
              fontSize: 'text-sm',
              height: 'h-12',
            }}
            onClick={(e) => {
              e.stopPropagation();
              history.push(`/pros/${reservation.pro.id}`);
            }}
          >
            내가 쓴 리뷰 보기
          </Button>
        ) : me.role === Role.USER &&
          reservation.type !== ReservationType.ROOM ? (
          <Button
            tw={{
              fontSize: 'text-sm',
              height: 'h-12',
            }}
            onClick={(e) => {
              e.stopPropagation();
              history.push(`/pros/${reservation.pro.id}/add-review`);
            }}
          >
            리뷰 남기기
          </Button>
        ) : (
          <></>
        ))}
    </div>
  );
};
