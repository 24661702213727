import {
  Button,
  FlexWrap,
  gql,
  LoadingScreen,
  NotificationButton,
  Screen,
  Section,
  TopNavbar,
  useQuery,
  useRecoilValue,
  utcToLocalFormat,
} from '@entropyparadox/reusable-react';
import React, { useState } from 'react';
import { CheckButton, DateSelect } from '../components';
import { meState } from '../store';
import { Branch, ReservationState, Room, Timeslot } from '../types';
import { ErrorPage } from './ErrorPage';
import 'moment/locale/ko';
import moment from 'moment';
import { ReactComponent as Logo } from '../assets/images/logo.svg';

const RoomsPageQuery = gql`
  query RoomsPage($branchId: Int!, $startOfDate: DateTime!) {
    branch(id: $branchId) {
      id
      name
      rooms {
        id
        name
        timeslots(startOfDate: $startOfDate) {
          id
          startedAt
          endedAt
          open
          isFallback
        }
        reservations(startOfDate: $startOfDate) {
          id
          startedAt
          endedAt
          state
        }
      }
    }
  }
`;

// const SendQuery = gql`
//   query sendMessage($date: String, $time: String, $room: String) {
//     sendMessage(date: $date, time: $time, room: $room) {
//       id
//     }
//   }
// `;

export const RoomsPage = () => {
  const me = useRecoilValue(meState);
  const membership = me?.memberships[0];
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [time, setTime] = useState('');
  const [room, setRoom] = useState<Room | undefined>();
  const [timeslotId, setTimeslotId] = useState<number | undefined>();

  console.log(time);

  const { loading, error, data } = useQuery<{
    branch: Branch;
  }>(RoomsPageQuery, {
    skip: membership === undefined,
    variables: {
      branchId: membership?.branchId,
      startOfDate: moment(date).toISOString(),
      date,
    },
  });

  // const [send] = useLazyQuery(SendQuery, {
  //   variables: {
  //     date,
  //     time,
  //     room: room?.name,
  //   },
  //   onCompleted: () => alert('예약 요청이 완료되었습니다.'),
  // });

  if (!me) return <LoadingScreen />;
  if (!membership) {
    return (
      <>
        <TopNavbar title="룸 예약" left={<NotificationButton />} />

        <Screen top bottom>
          <div className="flex-1 flex flex-col justify-center space-y-2">
            <Logo className="mx-auto w-14" />
            <h2 className="text-center font-bold text-lg">
              멤버십 회원만 예약이 가능합니다.
            </h2>
            <p className="text-center text-13 text-gray-500">
              골프 예약은 멤버쉽 회원만 예약이 가능하며 현장에서
              <br />
              가입 또는 아래 버튼을 통해 가입하실 수 있습니다.
            </p>

            <Section>
              <Button
                text="멤버십 가입하기"
                tw={{ backgroundColor: 'bg-brand-2', height: 'h-12' }}
              />
              <button
                className="text-sm"
                onClick={() =>
                  window.open('https://thesixstudio.channel.io/', '_blank')
                }
              >
                멤버십 문의하기 →
              </button>
            </Section>
          </div>
        </Screen>
      </>
    );
  }

  if (loading) return <LoadingScreen />;
  if (error || !data) return <ErrorPage />;

  // Find available timeslots. Can it be refactored?
  const allTimeslots =
    room?.timeslots.filter(
      (timeslot) =>
        timeslot.open &&
        room.reservations.every(
          (reservation) =>
            reservation.state === ReservationState.CANCELED ||
            moment(timeslot.endedAt) <= moment(reservation.startedAt) ||
            moment(timeslot.startedAt) >= moment(reservation.endedAt),
        ),
    ) || [];
  const { fallbackTimeslots, overwritingTimeslots } = allTimeslots.reduce(
    (prev, timeslot) => {
      if (timeslot.isFallback) {
        prev.fallbackTimeslots.push(timeslot);
      } else {
        prev.overwritingTimeslots.push(timeslot);
      }
      return prev;
    },
    {
      fallbackTimeslots: [] as Timeslot[],
      overwritingTimeslots: [] as Timeslot[],
    },
  );
  let timeslots = fallbackTimeslots.filter((f) =>
    overwritingTimeslots.every(
      (o) =>
        moment(f.endedAt) <= moment(o.startedAt) ||
        moment(f.startedAt) >= moment(o.endedAt),
    ),
  );
  timeslots = timeslots.concat(overwritingTimeslots);

  return (
    <>
      <TopNavbar title={data.branch.name} left={<NotificationButton />} />

      <Screen top bottom>
        <Section tw={{ backgroundColor: 'bg-gray-50' }}>
          <DateSelect
            value={date}
            onChange={(m) => {
              setDate(m);
              setRoom(undefined);
              setTimeslotId(undefined);
            }}
          />
        </Section>

        <div className="overflow-y-auto">
          <Section>
            <h3 className="font-bold">룸 선택</h3>
            <FlexWrap>
              {data.branch.rooms.map((r) => (
                <CheckButton
                  key={r.id}
                  active={r.id === room?.id}
                  onClick={() => {
                    setRoom(r);
                    setTimeslotId(undefined);
                  }}
                >
                  {r.name}
                </CheckButton>
              ))}
            </FlexWrap>
          </Section>

          <Section>
            {/* <h3 className="font-bold">예약 가능한 시간(*30분 기준)</h3> */}

            <FlexWrap>
              {[...timeslots]
                .sort(
                  (a, b) =>
                    new Date(a.startedAt).getHours() -
                    new Date(b.startedAt).getHours(),
                )
                .filter((timeslot) => {
                  const now = new Date().toISOString();
                  return now < timeslot.startedAt;
                })
                .map((timeslot) => {
                  const active = timeslot.id === timeslotId;
                  return (
                    <CheckButton
                      key={timeslot.id}
                      active={active}
                      onClick={() => {
                        setTimeslotId(active ? undefined : timeslot.id);
                        setTime(
                          active
                            ? ''
                            : utcToLocalFormat(timeslot.startedAt, 'a h:mm'),
                        );
                      }}
                    >
                      {utcToLocalFormat(timeslot.startedAt, 'a')}
                      <br />
                      {utcToLocalFormat(timeslot.startedAt, 'h:mm')}
                    </CheckButton>
                  );
                })}
            </FlexWrap>

            <div className="text-sm">
              {/* <p>*예약요청은 아래 전화하기 버튼으로 신청가능합니다.</p>
              <p className="text-red-500">
                *예약신청 가능시간 : 평일 09:00 ~ 20:00까지
              </p> */}
            </div>
          </Section>
        </div>

        <Section>
          <Button
            to={`/reservations/new?type=ROOM&branchId=${data.branch.id}&roomId=${room?.id}&date=${date}&timeslotId=${timeslotId}`}
            disabled={!room?.id || !timeslotId}
            // onClick={() => window.open('tel:031 8004 0289', '_self')}
            // onClick={() => send()}
          >
            예약하기
          </Button>
        </Section>
      </Screen>
    </>
  );
};
