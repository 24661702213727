import {
  AspectRatioImage,
  BackButton,
  gql,
  LoadingScreen,
  Section,
  TopNavbar,
  useIdParam,
  useQuery,
  utcToLocalDate,
} from '@entropyparadox/reusable-react';
import React from 'react';
import { Notice } from '../types';
import { ErrorPage } from './ErrorPage';
import { NotFoundPage } from './NotFoundPage';

const NoticePageQuery = gql`
  query NoticePage($id: Int!) {
    notice(id: $id) {
      id
      createdAt
      title
      text
      image
    }
  }
`;

export const NoticePage = () => {
  const id = useIdParam();
  const { loading, error, data } = useQuery<{
    notice: Notice;
  }>(NoticePageQuery, {
    skip: id === undefined,
    variables: { id },
  });

  if (!id) return <NotFoundPage />;
  if (loading) return <LoadingScreen />;
  if (error || !data) return <ErrorPage />;

  return (
    <>
      <TopNavbar title="공지사항 상세" left={<BackButton />} />

      <Section tw={{ spaceY: 'space-y-4' }}>
        <div>
          <h1 className="font-bold">{data.notice.title}</h1>
          <time className="text-xs text-gray-500">
            {utcToLocalDate(data.notice.createdAt)}
          </time>
        </div>

        {data.notice.image && (
          <AspectRatioImage
            src={data.notice.image}
            alt="notice"
            pb="pb-2/5"
            rounded="rounded"
          />
        )}
        <p
          className="flex-1 text-sm text-gray-400"
          dangerouslySetInnerHTML={{ __html: data.notice.text }}
        ></p>
      </Section>
    </>
  );
};
