import {
  BackButton,
  gql,
  List,
  LoadingScreen,
  SimpleListItem,
  TopNavbar,
  useQuery,
  utcToLocalDate,
} from '@entropyparadox/reusable-react';
import React from 'react';
import { Notice } from '../types';
import { ErrorPage } from './ErrorPage';

const NoticesPageQuery = gql`
  query NoticesPage {
    notices {
      id
      createdAt
      title
    }
  }
`;

export const NoticesPage = () => {
  const { loading, error, data } = useQuery<{
    notices: Notice[];
  }>(NoticesPageQuery);

  if (loading) return <LoadingScreen />;
  if (error || !data) return <ErrorPage />;

  return (
    <>
      <TopNavbar title="공지사항" left={<BackButton />} />

      <List>
        {data.notices.map((notice) => (
          <SimpleListItem
            key={notice.id}
            text={notice.title}
            subText={utcToLocalDate(notice.createdAt)}
            to={`/notices/${notice.id}`}
          />
        ))}
      </List>
    </>
  );
};
