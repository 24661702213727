import {
  Alert,
  alertState,
  useRecoilState,
} from '@entropyparadox/reusable-react';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const GlobalAlert = () => {
  const [alert, setAlert] = useRecoilState(alertState);
  const location = useLocation();
  useEffect(() => {
    setAlert({ ...alert, open: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.key]);

  return (
    <>
      {alert.open && (
        <div className="fixed top-16 z-10 w-full max-w-inherit px-5">
          <div className="mx-auto">
            <Alert
              severity={alert.severity}
              text={alert.text}
              onClose={() => setAlert({ ...alert, open: false })}
            />
          </div>
        </div>
      )}
    </>
  );
};
