import {
  BackButton,
  gql,
  LoadingScreen,
  Section,
  TopNavbar,
  useQuery,
  utcToLocalFormat,
} from '@entropyparadox/reusable-react';
import moment from 'moment';
import React, { useState } from 'react';
import { Reservation, ReservationType, Status } from '../types';
import { ErrorPage } from './ErrorPage';

export const SettlementPage = () => {
  const [getMoment, setMoment] = useState(moment());
  const today = getMoment;

  const SettlementPageQuery = gql`
    query SettlementPage($date: DateTime!) {
      myDateReservations(date: $date) {
        id
        createdAt
        type
        targetType
        startedAt
        endedAt
        payment {
          id
          finalPrice
          status
        }
        user {
          id
          name
        }
      }
    }
  `;

  const { loading, error, data } = useQuery<{
    myDateReservations: Reservation[];
  }>(SettlementPageQuery, {
    variables: {
      date: getMoment.toISOString(),
    },
  });

  if (loading) return <LoadingScreen />;
  if (error) return <ErrorPage />;

  const findCallPrice = data?.myDateReservations
    .filter(
      (reservation) =>
        reservation.type === ReservationType.CALL &&
        reservation.payment?.status === Status.SUCCESS,
    )
    .map((reservation) => reservation.payment?.finalPrice);

  const totalCallPrice = findCallPrice
    ?.filter((callPrice) => callPrice !== undefined)
    .reduce((a, b) => a + b, 0);

  const findLivePrice = data?.myDateReservations
    .filter(
      (reservation) =>
        reservation.type === ReservationType.LIVE &&
        reservation.payment?.status === Status.SUCCESS,
    )
    .map((reservation) => reservation.payment?.finalPrice);

  const totalLivePrice = findLivePrice
    ?.filter((livePrice) => livePrice !== undefined)
    .reduce((a, b) => a + b, 0);

  const totalPrice = Number(totalCallPrice) + Number(totalLivePrice);

  return (
    <>
      <TopNavbar title="정산 내역" left={<BackButton />} />
      <div className="w-full text-center my-7 text-2xl font-bold">
        <div>
          <button
            className="w-7"
            onClick={() => {
              setMoment(getMoment.clone().subtract(1, 'month'));
            }}
          >
            {'<'}
          </button>
          <span className="mx-7">{today.format('YYYY 년 MM 월')}</span>
          <button
            className="w-7"
            onClick={() => {
              setMoment(getMoment.clone().add(1, 'month'));
            }}
          >
            {'>'}
          </button>
        </div>
      </div>

      <div className="bg-brand-1 h-16 text-white font-bold flex items-center pl-5">
        이번달 통계
      </div>

      <Section tw={{ flexDirection: 'flex-row' }}>
        <table className="font-bold text-md table-auto w-2/4 leading-10">
          <tr>
            <td>전화 레슨</td>
            <td>
              {String(totalCallPrice).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </td>
          </tr>
          <tr>
            <td>라이브 레슨</td>
            <td>
              {String(totalLivePrice).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </td>
          </tr>
          <tr>
            <td>총 합계</td>
            <td>{String(totalPrice).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</td>
          </tr>
        </table>
      </Section>
      <div className=" text-brand-1 flex items-center">
        <table className="w-full table-auto">
          <thead>
            <tr className="h-16 font-bold bg-gray-100 text-left">
              <th className="pl-5">예약일자</th>
              <th>예약정보</th>
              <th className="pr-5">결제금액</th>
            </tr>
          </thead>
          <tbody>
            {data?.myDateReservations
              .filter(
                (reservation) => reservation.payment?.status === Status.SUCCESS,
              )
              .map((reservation) => (
                <tr className="border-b text-sm">
                  <td className="pl-5 py-5 w-5/12">
                    {utcToLocalFormat(
                      reservation.createdAt,
                      'YYYY.MM.DD a hh:mm',
                    )}
                  </td>
                  <td className="py-5">
                    {reservation.type === ReservationType.LIVE
                      ? '라이브 레슨'
                      : reservation.type === ReservationType.CALL
                      ? '전화 레슨'
                      : ''}{' '}
                    {utcToLocalFormat(reservation.startedAt, 'YYYY.MM.DD')}
                    <br />
                    {utcToLocalFormat(reservation.startedAt, 'HH:mm')} -
                    {utcToLocalFormat(reservation.endedAt, 'HH:mm')}
                    <br />
                    {reservation.user.name}
                  </td>
                  <td className="pr-5 py-5">
                    {String(reservation.payment?.finalPrice).replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ',',
                    )}
                    원
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
