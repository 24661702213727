import {
  appleIdState,
  BackButton,
  Button,
  kakaoIdState,
  Section,
  TextField,
  TopNavbar,
  useLogin,
  useLoginWithApple,
  useLoginWithKakao,
  useLoginWithKakaoWeb,
  useRecoilValue,
  useShowAlert,
} from '@entropyparadox/reusable-react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Bridge } from '../bridge';
import AppleButton from '../assets/images/appleButton.png';
import { appleName, osState } from '../plugins/ridge';

export const EmailLoginPage = () => {
  const history = useHistory();
  const showAlert = useShowAlert();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const os = osState.useValue();
  const login = useLogin(email, password, (error) =>
    showAlert('error', error.message),
  );
  const loginWithKakao = useLoginWithKakao((error) =>
    showAlert('error', error.message),
  );
  const loginWithApple = useLoginWithApple((error) =>
    showAlert('error', error.message),
  );
  Bridge.onLoginWithKakao = ({ accessToken }: any) => {
    loginWithKakao(accessToken);
  };
  Bridge.onLoginWithApple = ({ identityToken, fullName }: any) => {
    appleName.set(fullName);
    loginWithApple(identityToken);
  };
  const loginWithKakaoWeb = useLoginWithKakaoWeb((error) =>
    showAlert('error', error.message),
  );

  const kakaoId = useRecoilValue(kakaoIdState);
  const appleId = useRecoilValue(appleIdState);
  useEffect(() => {
    if (kakaoId || appleId) {
      history.push('/signup');
    }
  }, [history, kakaoId, appleId]);

  return (
    <>
      <TopNavbar title="로그인" left={<BackButton />} />

      <Section>
        <TextField
          type="email"
          label="이메일 주소"
          placeholder="이메일 주소를 입력해주세요."
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          type="password"
          label="비밀번호"
          placeholder="비밀번호를 입력해주세요"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Section>

      <Section>
        <Button
          text="로그인"
          tw={{ backgroundColor: 'bg-gray-800' }}
          disabled={!email || !password}
          onClick={() => login()}
        />
        <Button text="회원가입" variant="outlined" to="/signup" />
        <p className="text-center">or</p>
        <Button
          text="카카오로 시작하기"
          tw={{ backgroundColor: 'bg-kakao-1', color: 'text-kakao-2' }}
          onClick={() =>
            os ? Bridge.postMessage('loginWithKakao') : loginWithKakaoWeb()
          }
        />
        {os === 'ios' && (
          <button
            style={{
              backgroundImage: `url(${AppleButton})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              border: '1px solid #000',
            }}
            className="w-full h-13 rounded-lg"
            onClick={() => Bridge.postMessage('loginWithApple')}
          ></button>
        )}
      </Section>
    </>
  );
};
