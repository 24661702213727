import { gql, TextArea, useMutation } from '@entropyparadox/reusable-react';
import React, { FC, useState } from 'react';
import { QuestionPageQuery } from '../pages/QuestionPage';
import { Comment } from '../types';
import { SmallTextButton } from './SmallTextButton';

const CreateComment = gql`
  mutation CreateComment($text: String!, $questionId: Int!) {
    createComment(text: $text, questionId: $questionId) {
      id
    }
  }
`;

const UpdateComment = gql`
  mutation UpdateComment($id: Int!, $text: String!) {
    updateComment(id: $id, text: $text) {
      id
    }
  }
`;

interface CommentEditorProps {
  questionId: number;
  comment?: Comment;
  onFinished: () => void;
}

export const CommentEditor: FC<CommentEditorProps> = ({
  questionId,
  comment,
  onFinished,
}) => {
  const isNewComment = comment === undefined;
  const [text, setText] = useState(comment?.text ?? '');

  const [createComment] = useMutation(CreateComment, {
    variables: { text, questionId },
    refetchQueries: [
      { query: QuestionPageQuery, variables: { id: questionId } },
    ],
    onCompleted: onFinished,
  });

  const [updateComment] = useMutation(UpdateComment, {
    variables: { id: comment?.id, text },
    refetchQueries: [
      { query: QuestionPageQuery, variables: { id: questionId } },
    ],
    onCompleted: onFinished,
  });

  return (
    <>
      <TextArea
        placeholder="댓글을 입력하세요."
        autoFocus
        value={text}
        onChange={(e) => setText(e.target.value)}
      />

      <div className="flex justify-end space-x-1">
        <SmallTextButton variant="cancel" onClick={onFinished} />
        <SmallTextButton
          variant="save"
          disabled={!text}
          onClick={isNewComment ? () => createComment() : () => updateComment()}
        />
      </div>
    </>
  );
};
