import {
  Divider,
  gql,
  useMutation,
  utcToLocalFromNow,
} from '@entropyparadox/reusable-react';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { ReactComponent as More } from '../assets/images/icon-more-horizontal.svg';
import { Review, User } from '../types';

interface ReviewCardProps {
  review: Review;
  me?: User;
  refetch: () => void;
}

const DeletedReview = gql`
  mutation DeletedReview($proId: Int!) {
    deletedReview(proId: $proId) {
      id
    }
  }
`;

const ReviewCard: React.FC<ReviewCardProps> = ({ review, me, refetch }) => {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();

  const [deleteReview] = useMutation(DeletedReview, {
    variables: { proId: review.proId },
    onCompleted: async () => {
      await refetch();
      alert('삭제가 완료되었습니다.');
    },
  });

  const handleDeleted = () => {
    // eslint-disable-next-line no-restricted-globals
    const isDeleted = confirm('정말 삭제하시겠습니까?');
    if (isDeleted) {
      deleteReview();
    }
  };

  return (
    <div className="relative">
      <div className="flex justify-between">
        <div className="flex flex-wrap">
          {review.compliments.map((compliment) => (
            <div
              key={compliment.id}
              className="px-4 py-2 rounded-full bg-gray-100
                  whitespace-nowrap text-13 text-brand-2 mr-2 mb-2"
            >
              {compliment.text}
            </div>
          ))}
        </div>
        {me?.id === review.user.id && (
          <div>
            <More onClick={() => setIsOpen(!isOpen)} />
          </div>
        )}
      </div>
      <p className="text-sm break-all">{review.content}</p>
      <div className="text-13 text-gray-400 mb-4">
        {review.user.name} ・ {utcToLocalFromNow(review.createdAt)}
      </div>
      <Divider />
      {isOpen === true && (
        <div className="text-sm rounded-md shadow bg-white absolute top-8 right-0">
          <div
            className="py-3 px-4"
            onClick={() => history.push(`/reviews/${review.id}/edit`)}
          >
            수정하기
          </div>
          <div
            className="py-3 px-4 text-red-500"
            onClick={() => handleDeleted()}
          >
            삭제하기
          </div>
        </div>
      )}
    </div>
  );
};

export default ReviewCard;
