import moment from 'moment';
import React, { FC } from 'react';
// import { useRecoilValue } from 'recoil';
// import { meState } from '../store';
// import { Role } from '../types';

interface DateSelectProps {
  value: string;
  onChange: (date: string) => void;
}

export const DateSelect: FC<DateSelectProps> = ({ value, onChange }) => {
  // const me = useRecoilValue(meState);

  return (
    <>
      <div className="text-center font-bold text-lg text-gray-800">
        {moment().format('YYYY년 MM월')}
      </div>

      <div className="flex justify-between">
        {Array.from(Array(7).keys())
          .map((day) => moment().add(day, 'days'))
          .map((m) => {
            const selected = m.isSame(value, 'date');
            const isToday = m.isSame(moment(), 'date');
            const new_date = moment(moment(), 'date').add(3, 'days');
            const checked = m.isBefore(new_date, 'date');

            return (
              <div
                key={m.date()}
                className="flex flex-col items-center"
                onClick={() => {
                  if (checked) {
                    return onChange(m.format('YYYY-MM-DD'));
                  } else {
                    alert('오늘 기준 3일 후 예약까지만 가능합니다.');
                  }
                }}
              >
                <div
                  className={`flex justify-center items-center w-8 h-8 font-bold text-lg ${
                    selected && 'rounded-full bg-gray-800 text-white'
                  }`}
                >
                  {m.date()}
                </div>
                <div
                  className={`text-xs ${
                    isToday ? 'text-brand-2' : 'text-gray-500'
                  }`}
                >
                  {isToday ? '오늘' : m.format('dddd')}
                </div>
              </div>
            );
          })}
        {/* </>
        )} */}
        {/* {me?.role === Role.PRO && (
          <>
            {Array.from(Array(8).keys())
              .map((day) => moment().add(day, 'days'))
              .map((m) => {
                const selected = m.isSame(value, 'date');
                const isToday = m.isSame(moment(), 'date');

                return (
                  <div
                    key={m.date()}
                    className="flex flex-col items-center"
                    onClick={() => onChange(m.format('YYYY-MM-DD'))}
                  >
                    <div
                      className={`flex justify-center items-center w-8 h-8 font-bold text-lg ${
                        selected && 'rounded-full bg-gray-800 text-white'
                      }`}
                    >
                      {m.date()}
                    </div>
                    <div
                      className={`text-xs ${
                        isToday ? 'text-brand-2' : 'text-gray-500'
                      }`}
                    >
                      {isToday ? '오늘' : m.format('dddd')}
                    </div>
                  </div>
                );
              })}
          </>
        )} */}
      </div>
    </>
  );
};
