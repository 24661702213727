import {
  appleIdState,
  Button,
  kakaoIdState,
  Screen,
  Section,
  TopNavbar,
  useLoginWithApple,
  useLoginWithKakao,
  useLoginWithKakaoWeb,
  useRecoilValue,
  useShowAlert,
} from '@entropyparadox/reusable-react';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Logo } from '../assets/images/new_logo.svg';
import AppleButton from '../assets/images/appleButton.png';

// import { ReactComponent as TextLogo } from '../assets/images/text_logo.svg';
import { Bridge } from '../bridge';
import { appleName, osState } from '../plugins/ridge';

export const LoginPage = () => {
  const history = useHistory();
  const os = osState.useValue();
  const showAlert = useShowAlert();
  const loginWithKakao = useLoginWithKakao((error) =>
    showAlert('error', error.message),
  );
  const loginWithApple = useLoginWithApple((error) =>
    showAlert('error', error.message),
  );
  Bridge.onLoginWithKakao = ({ accessToken }: any) => {
    loginWithKakao(accessToken);
  };
  Bridge.onLoginWithApple = ({ identityToken, fullName }: any) => {
    appleName.set(fullName);
    loginWithApple(identityToken);
  };
  const loginWithKakaoWeb = useLoginWithKakaoWeb((error) =>
    showAlert('error', error.message),
  );

  const kakaoId = useRecoilValue(kakaoIdState);
  const appleId = useRecoilValue(appleIdState);
  useEffect(() => {
    if (kakaoId || appleId) {
      history.push('/signup');
    }
  }, [history, kakaoId, appleId]);

  return (
    <>
      <TopNavbar title="로그인" />

      <Screen top bottom>
        <div className="flex-1 flex flex-col justify-center items-center space-y-2">
          <Logo className="mx-auto w-1/2" />
          <h2 className="font-bold text-xl pt-4">아직 회원이 아니신가요?</h2>
          <p className="text-13 text-gray-500">
            로그인 / 회원가입 후 사용해주세요.
          </p>
        </div>

        <Section>
          <Button
            text="카카오로 시작하기"
            tw={{ backgroundColor: 'bg-kakao-1', color: 'text-kakao-2' }}
            onClick={() =>
              os ? Bridge.postMessage('loginWithKakao') : loginWithKakaoWeb()
            }
          />
          {os === 'ios' && (
            <button
              style={{
                backgroundImage: `url(${AppleButton})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                border: '1px solid #000',
              }}
              className="w-full h-13 rounded-lg"
              onClick={() => Bridge.postMessage('loginWithApple')}
            ></button>
          )}
          <Button
            text="이메일로 로그인"
            to="/email-login"
            variant="outlined"
            tw={{ borderColor: 'border-gray-800', color: 'text-gray-800' }}
          />
          <p className="text-center">or</p>
          <Button
            text="회원가입"
            to="/signup"
            tw={{ backgroundColor: 'bg-gray-800' }}
          />
        </Section>
      </Screen>
    </>
  );
};
