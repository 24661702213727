import { BackButton, TopNavbar } from '@entropyparadox/reusable-react';
import React from 'react';

export const ErrorPage = () => {
  return (
    <>
      <TopNavbar title="Error" left={<BackButton />} />

      <p>네트워크 에러가 발생했습니다.</p>
    </>
  );
};
