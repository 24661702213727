import {
  List,
  LoadingScreen,
  NotificationButton,
  SimpleListItem,
  TopNavbar,
  useRecoilValue,
} from '@entropyparadox/reusable-react';
import React from 'react';
import { meState } from '../store';
import { Role } from '../types';
import ChannelService from '../ChannelService';

export const MyPage = () => {
  const me = useRecoilValue(meState);

  if (!me) return <LoadingScreen />;

  ChannelService.boot({
    pluginKey: process.env.REACT_APP_CANNELIO_KEY,
    hideChannelButtonOnBoot: true,
    mobileMessengerMode: 'iframe',
    zIndex: 1,
    profile: {
      name: me.name,
      profileMobileNumber: me.phone,
      avatarUrl: me.avatar,
      profileEmail: me.email,
      member: me.memberships.length > 0 ? true : false,
      memberId: me.id,
    },
    customLauncherSelector: '#cannelIo-button2',
  });

  return (
    <>
      <TopNavbar title="마이페이지" left={<NotificationButton />} />

      <List>
        <SimpleListItem text="내 정보 수정" to="/me/edit" />
        {me.role === Role.PRO && !process.env.REACT_APP_HIDE_MENU && (
          <>
            <SimpleListItem text="스케줄 설정" to="/timetable/edit" />
            <SimpleListItem text="비용 설정" to="/pricing/edit" />
            <SimpleListItem text="정산 내역" to="/settlement" />
          </>
        )}
        <SimpleListItem
          text="FAQs"
          onClick={() => {
            window.open(
              'https://www.notion.so/The-six-studio-FAQ-38a843543ab245cb824df65292c776aa',
              '_blank',
            );
          }}
        />
        <SimpleListItem text="공지사항" to="/notices" />
        <SimpleListItem
          text="문의하기"
          id="cannelIo-button2"
          className="px-5 w-full"
        />
        <SimpleListItem
          text="이용약관"
          onClick={() => {
            window.open(
              'https://www.notion.so/The-six-studio-d443eb9b333b430fb540a0f97075481d',
              '_blank',
            );
          }}
        />

        <SimpleListItem
          text="개인정보취급방침"
          onClick={() => {
            window.open(
              'https://www.notion.so/The-six-studio-2dc6d57653cb47dc91ddfff9592e4c56',
              '_blank',
            );
          }}
        />
      </List>
    </>
  );
};
