import {
  BackButton,
  Chip,
  Divider,
  gql,
  HorizontalScrollView,
  LoadingScreen,
  Section,
  Tab,
  Tabs,
  TopNavbar,
  useQuery,
  useQueryParams,
} from '@entropyparadox/reusable-react';
import React, { useState } from 'react';
import { NoSearchResult, ProCard, ProVideoCard } from '../components';
import { ProVideo, Tag, User } from '../types';
import { ErrorPage } from './ErrorPage';

const TagSearchPageQuery = gql`
  query TagSearchPage($ids: [Int!]!) {
    tagsByIds(ids: $ids) {
      id
      name
    }
    prosByTagIds(tagIds: $ids) {
      id
      name
      callPrice
      livePrice
    }
    proVideosByTagIds(tagIds: $ids) {
      id
      video
      user {
        id
        name
      }
      tag {
        id
        name
      }
    }
    recommendedPros {
      id
      name
      callPrice
      livePrice
      avatar
    }
    recommendedProVideos {
      id
      video
      user {
        id
        name
      }
      tag {
        id
        name
      }
    }
  }
`;

export const TagSearchPage = () => {
  const { ids } = useQueryParams();
  const [tabIndex, setTabIndex] = useState(0);
  const { loading, error, data } = useQuery<{
    tagsByIds: Tag[];
    prosByTagIds: User[];
    proVideosByTagIds: ProVideo[];
    recommendedPros: User[];
    recommendedProVideos: ProVideo[];
  }>(TagSearchPageQuery, {
    variables: { ids },
  });

  if (loading) return <LoadingScreen />;
  if (error || !data) return <ErrorPage />;
  return (
    <>
      <TopNavbar title="분야 선택 결과" left={<BackButton />} borderless />

      <div className="py-4">
        <HorizontalScrollView>
          {data.tagsByIds.map((tag) => (
            <Chip key={tag.id} text={tag.name} active />
          ))}
        </HorizontalScrollView>
      </div>

      <Tabs sticky tw={{ paddingX: 'px-3' }}>
        <Tab
          text="프로"
          twInnerActive={{
            borderColor: 'border-brand-2',
            color: 'text-brand-2',
          }}
          active={tabIndex === 0}
          onClick={() => setTabIndex(0)}
        />
        <Tab
          text="영상"
          twInnerActive={{
            borderColor: 'border-brand-2',
            color: 'text-brand-2',
          }}
          active={tabIndex === 1}
          onClick={() => setTabIndex(1)}
        />
      </Tabs>

      <Section>
        {tabIndex === 0 && (
          <>
            {data.prosByTagIds.length > 0 ? (
              data.prosByTagIds.map((pro) => (
                <ProCard key={pro.id} pro={pro} variant="detailed" />
              ))
            ) : (
              <NoSearchResult />
            )}
            <Divider />
            <h3 className="font-bold text-lg">추천 프로</h3>
            {data.recommendedPros.map((pro) => (
              <ProCard key={pro.id} pro={pro} variant="detailed" />
            ))}
          </>
        )}

        {tabIndex === 1 && (
          <>
            {data.proVideosByTagIds.length > 0 ? (
              <div className="grid grid-cols-2 gap-x-3 gap-y-6">
                {data.proVideosByTagIds.map((proVideo) => (
                  <ProVideoCard
                    key={proVideo.id}
                    proVideo={proVideo}
                    subtitle={`${proVideo.user.name} 프로`}
                  />
                ))}
              </div>
            ) : (
              <NoSearchResult />
            )}
            <Divider />
            <h3 className="font-bold text-lg">추천 영상</h3>
            <div className="grid grid-cols-2 gap-x-3 gap-y-6">
              {data.recommendedProVideos.map((proVideo) => (
                <ProVideoCard
                  key={proVideo.id}
                  proVideo={proVideo}
                  subtitle={`${proVideo.user.name} 프로`}
                />
              ))}
            </div>
          </>
        )}
      </Section>
    </>
  );
};
