import {
  BackButton,
  BottomFixedButton,
  Divider,
  FlexWrap,
  gql,
  LoadingScreen,
  Section,
  TextArea,
  TopNavbar,
  useMutation,
  useQuery,
  useQueryParams,
  utcToLocalFormat,
} from '@entropyparadox/reusable-react';
import moment from 'moment';
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CheckButton } from '../components';
import {
  Reservation,
  ReservationTargetType,
  ReservationType,
  Timeslot,
  User,
} from '../types';
import { ErrorPage } from './ErrorPage';
//@ts-ignore
import BootPay from 'bootpay-js';

const ProReservationAddPageQuery = gql`
  query ProReservationAddPage($userId: Int!, $timeslotId: Int!) {
    user(id: $userId) {
      id
      name
      callPrice
      livePrice
    }
    timeslot(id: $timeslotId) {
      id
      startedAt
      endedAt
    }
    paymentAccessToken {
      userToken
    }
    checkReservationExists(targetId: $userId, timeslotId: $timeslotId) {
      id
    }
  }
`;

const MakeReservation = gql`
  mutation MakeReservation(
    $targetType: ReservationTargetType!
    $userId: Int!
    $reservationType: ReservationType!
    $startOfDate: DateTime!
    $timeslotId: Int!
  ) {
    makeReservation(
      targetType: $targetType
      targetId: $userId
      type: $reservationType
      startOfDate: $startOfDate
      timeslotId: $timeslotId
    ) {
      id
    }
  }
`;

const CreatePayment = gql`
  mutation CreatePayment(
    $userId: Int!
    $receipt_id: String!
    $reservationType: ReservationType!
    $targetType: ReservationTargetType!
    $startOfDate: DateTime!
    $timeslotId: Int!
    $orderId: String!
    $card_name: String!
    $card_no: String!
    $note: String!
  ) {
    createPayment(
      targetId: $userId
      orderId: $orderId
      receipt_id: $receipt_id
      type: $reservationType
      targetType: $targetType
      startOfDate: $startOfDate
      timeslotId: $timeslotId
      card_name: $card_name
      card_no: $card_no
      note: $note
    ) {
      id
    }
  }
`;

interface ProReservationAddPageProps {
  reservationType: ReservationType;
}

export const ProReservationAddPage: FC<ProReservationAddPageProps> = ({
  reservationType,
}) => {
  const history = useHistory();
  const { userId, date, timeslotId } = useQueryParams();
  const [note, setNote] = useState<string>('');

  const { loading, error, data } = useQuery<{
    user: User;
    timeslot: Timeslot;
    paymentAccessToken: {
      userToken: string;
    };
    checkReservationExists: Reservation;
  }>(ProReservationAddPageQuery, {
    variables: { userId, timeslotId },
    nextFetchPolicy: 'cache-first',
  });

  const callPrice =
    reservationType === ReservationType.CALL && data?.user.callPrice;

  const livePrice =
    reservationType === ReservationType.LIVE && data?.user.livePrice;

  const handleBootPay = () => {
    let checkReservationExists = data?.checkReservationExists;
    if (!checkReservationExists) {
      const orderId = data?.user.id + '_' + new Date().getTime();
      BootPay.request({
        price:
          (reservationType === ReservationType.LIVE && livePrice) ||
          (reservationType === ReservationType.CALL && callPrice),
        application_id: process.env.REACT_APP_BOOTPAY_KEY,
        name: 'The Six Studio',
        pg: 'payapp',
        method: 'easy_card',
        user_token: data?.paymentAccessToken.userToken,
        show_agree_window: 0,
        user_info: {
          username: data?.user.name,
          email: data?.user.email,
          phone: data?.user.phone,
        },
        order_id: orderId,
        extra: {
          start_at: '',
          end_at: '',
          theme: 'custom',
          custom_background: '#133132',
          custom_font_color: '#d1ab83',
        },
      })
        .error(function (data: any) {
          console.log(data);
          alert(data.msg);
          if (data.code === -74002 || data.code === -74005) {
            window.location.reload();
          }
        })
        .cancel(function (data: any) {
          console.log(data);
          alert(data.message);
        })
        .done(function (data: any) {
          console.log(data);
          createPayment({
            variables: {
              receipt_id: data.receipt_id,
              userId,
              reservationType,
              targetType: ReservationTargetType.USER,
              startOfDate: moment(date as string).toISOString(),
              timeslotId,
              orderId,
              card_name: data.payment_data.card_name,
              card_no: data.payment_data.card_no,
              note,
            },
          });
        });
    } else {
      alert('이미 예약된 시간입니다.다른 시간을 선택해주세요.');
    }
  };

  const [createPayment] = useMutation(CreatePayment, {
    onCompleted: () => history.push('/reservation-saved?result=made'),
    onError: (err) => alert(err.message),
  });

  const [makeProReservation] = useMutation(MakeReservation, {
    variables: {
      targetType: ReservationTargetType.USER,
      userId,
      reservationType,
      startOfDate: moment(date as string).toISOString(),
      timeslotId,
    },
    onCompleted: () => history.push('/reservation-saved?result=made'),
    onError: (err) => alert(err.message),
  });

  if (loading) return <LoadingScreen />;
  if (error || !data) return <ErrorPage />;

  return (
    <>
      <TopNavbar title="예약 확인 및 결제" left={<BackButton />} />

      <Section tw={{ spaceY: 'space-y-2' }}>
        <h3 className="font-bold">
          {reservationType === ReservationType.CALL
            ? '전화 레슨'
            : '원격 라이브 레슨'}
        </h3>
        <div className="text-sm text-gray-600">{data.user.name} 프로</div>
      </Section>

      <Section tw={{ spaceY: 'space-y-2' }}>
        <h3 className="font-bold">예약 날짜 및 시간 확인</h3>
        <div className="text-sm text-gray-600">
          {moment(date as string).format('LL')}
        </div>
        <FlexWrap>
          <CheckButton active>
            {utcToLocalFormat(data.timeslot.startedAt, 'a')}
            <br />
            {utcToLocalFormat(data.timeslot.startedAt, 'h:mm')}
          </CheckButton>
        </FlexWrap>
      </Section>

      <Section>
        <Divider />
      </Section>

      <Section tw={{ spaceY: 'space-y-2' }}>
        <h3 className="font-bold">코칭 주제</h3>
        <TextArea
          placeholder="프로님에게 받고싶은 코칭 내용을 입력해주세요."
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
      </Section>

      <Section>
        <Divider />
      </Section>

      <Section>
        <h3 className="font-bold">결제 금액</h3>
        <div className="flex text-sm items-center justify-between">
          <div className="text-gray-600">비대면 영상 코칭 1시간</div>
          <div className="text-gray-600">
            ₩
            {String(callPrice || livePrice).replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ',',
            )}
          </div>
        </div>
        <div className="flex items-center justify-between font-bold">
          <div>합계 (KRW)</div>
          <div>
            ₩
            {String(callPrice || livePrice).replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ',',
            )}
          </div>
        </div>
      </Section>

      <Section>
        <Divider />
      </Section>

      <Section>
        <h3 className="font-bold">주의사항</h3>
        <div className="text-sm text-gray-600">
          예약 후 48시간 이내 취소하면 수수료가 부과되지 않습니다. 그 이 후 6월
          22일 3:00 PM 전에 예약을 취소하면 서비스 수수료를 제 외한 요금 전액이
          환불됩니다.{' '}
          <span className="font-bold underline">자세히 알아보기</span>
        </div>
        <Divider />
        <div className="text-sm text-gray-600">
          호스트가 제공하는 환불 정책이 내게 적합한지 확인하세요. 3월 15 일 혹은
          그 이후 확정된 예약에는 코로나19 확산 사태에 따른 정상 참작이 가능한
          상황 정책이 적용되지 않습니다.{' '}
          <span className="font-bold underline">자세히 알아보기</span>
        </div>
      </Section>

      <BottomFixedButton
        tw={{ height: 'h-16' }}
        onClick={() =>
          process.env.REACT_APP_SKIP_PAYMENT
            ? makeProReservation()
            : handleBootPay()
        }
      >
        <div className="text-brand-2 font-bold">확인 및 결제</div>
      </BottomFixedButton>
    </>
  );
};
