import {
  Avatar,
  BackButton,
  BottomFixedButton,
  Chip,
  Divider,
  FlexWrap,
  gql,
  LoadingScreen,
  Section,
  TextArea,
  TopNavbar,
  useIdParam,
  useMutation,
  useQuery,
} from '@entropyparadox/reusable-react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Compliment, User } from '../types';
import { ErrorPage } from './ErrorPage';
import { NotFoundPage } from './NotFoundPage';

const ReviewAddPageQuery = gql`
  query ReviewAddPage($id: Int!) {
    compliments {
      id
      text
    }
    user(id: $id) {
      id
      name
      avatar
    }
  }
`;

const CreateReview = gql`
  mutation CreateReview(
    $proId: Int!
    $content: String!
    $complimentIds: [Int!]!
  ) {
    createReview(
      proId: $proId
      content: $content
      complimentIds: $complimentIds
    ) {
      id
    }
  }
`;

export const ReviewAddPage = () => {
  const id = useIdParam();
  const history = useHistory();
  const [content, setContent] = useState('');
  const [selectedComplimentIds, setSelectedComplimentIds] = useState<number[]>(
    [],
  );

  const { loading, error, data } = useQuery<{
    compliments: Compliment[];
    user: User;
  }>(ReviewAddPageQuery, { variables: { id } });

  const [createReview] = useMutation(CreateReview, {
    variables: { proId: id, content, complimentIds: selectedComplimentIds },
    onCompleted: () => {
      alert('리뷰 작성이 완료되었습니다.');
      history.go(-1);
    },
  });

  if (!id) return <NotFoundPage />;
  if (loading) return <LoadingScreen />;
  if (error || !data) return <ErrorPage />;

  return (
    <>
      <TopNavbar title="리뷰 남기기" left={<BackButton />} />

      <div className="flex justify-between items-center p-5">
        <div className="mr-3">
          <Avatar src={data.user.avatar} size={18} />
        </div>
        <div className="flex-1">
          <div className="font-bold text-13">{`${data.user.name} 프로`}</div>
        </div>
      </div>

      <Divider />

      <Section>
        <h3 className="font-bold text-lg">
          레슨을 받으며 좋았던 점을 선택해주세요.
        </h3>
        <FlexWrap>
          {data.compliments.map((compliment) => (
            <Chip
              key={compliment.id}
              text={compliment.text}
              onClick={() =>
                selectedComplimentIds.includes(compliment.id)
                  ? setSelectedComplimentIds(
                      selectedComplimentIds.filter(
                        (id: number) => id !== compliment.id,
                      ),
                    )
                  : setSelectedComplimentIds(
                      selectedComplimentIds.concat(compliment.id),
                    )
              }
              active={selectedComplimentIds.includes(compliment.id)}
            />
          ))}
        </FlexWrap>
        <Divider />
        <h3 className="font-bold text-lg">프로님과의 레슨이 어떠셨나요?</h3>
        <TextArea
          placeholder="100자 이내로 적어주세요."
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
        <div className="text-sm text-gray-500">
          *리뷰를 등록하면 프로 상세페이지에 노출됩니다.
        </div>
      </Section>

      <BottomFixedButton
        tw={{ height: 'h-16' }}
        onClick={() => createReview()}
        disabled={!content || selectedComplimentIds.length === 0}
      >
        <div className="text-brand-2 font-bold">리뷰 등록하기</div>
      </BottomFixedButton>
    </>
  );
};
