import {
  AspectRatioImage,
  BackButton,
  BottomFixedView,
  BoxTab,
  Button,
  Divider,
  gql,
  HomeButton,
  HorizontalScrollView,
  LoadingScreen,
  Section,
  Tabs,
  TopNavbar,
  useIdParam,
  useQuery,
  useRecoilValue,
  utcToLocalFormat,
} from '@entropyparadox/reusable-react';
import moment from 'moment';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { CheckButton, DateSelect, ProVideoCard } from '../components';
import ReviewCard from '../components/ReviewCard';
import { meState } from '../store';
import {
  ReservationState,
  ReservationType,
  Review,
  Role,
  Timeslot,
  User,
} from '../types';
import { ErrorPage } from './ErrorPage';
import { NotFoundPage } from './NotFoundPage';
import { ReactComponent as StarIcon } from '../assets/images/icon-star.svg';

const ProPageQuery = gql`
  query ProPage(
    $id: Int!
    $userId: Int
    $reservationType: ReservationType!
    $startOfDate: DateTime!
    $proId: Int!
  ) {
    user(id: $id) {
      id
      name
      career
      callPrice
      livePrice
      avatar
      title
      review(userId: $userId) {
        id
      }
      canReview
      proVideos {
        id
        video
        tag {
          id
          name
        }
      }
      timeslots(reservationType: $reservationType, startOfDate: $startOfDate) {
        id
        startedAt
        endedAt
        open
        isFallback
      }
      reservations(startOfDate: $startOfDate) {
        id
        targetId
        startedAt
        endedAt
        state
        user {
          id
        }
      }
    }
    reviewsByProId(proId: $proId) {
      id
      content
      createdAt
      proId
      user {
        id
        name
      }
      compliments {
        id
        text
      }
    }
  }
`;

export const ProPage = () => {
  const id = useIdParam();
  const me = useRecoilValue(meState);
  const [reservationType, setReservationType] = useState(ReservationType.CALL);
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [timeslot, setTimeslot] = useState<Timeslot | undefined>();
  const history = useHistory();

  const { loading, error, data, refetch } = useQuery<{
    user: User;
    reviewsByProId: Review[];
  }>(ProPageQuery, {
    skip: id === undefined,
    variables: {
      id,
      reservationType,
      startOfDate: moment(date).toISOString(),
      userId: me?.role === Role.USER ? me?.id : 1,
      proId: id,
    },
    onCompleted: () => setTimeslot(undefined),
  });

  if (!id) return <NotFoundPage />;
  if (loading) return <LoadingScreen />;
  if (error || !data) return <ErrorPage />;

  // Find available timeslots. Can it be refactored?
  const allTimeslots = data.user.timeslots.filter(
    (timeslot) =>
      timeslot.open &&
      data.user.reservations.every(
        (reservation) =>
          reservation.state === ReservationState.CANCELED ||
          moment(timeslot.endedAt) <= moment(reservation.startedAt) ||
          moment(timeslot.startedAt) >= moment(reservation.endedAt),
      ),
  );

  const { fallbackTimeslots, overwritingTimeslots } = allTimeslots.reduce(
    (prev, timeslot) => {
      if (timeslot.isFallback) {
        prev.fallbackTimeslots.push(timeslot);
      } else {
        prev.overwritingTimeslots.push(timeslot);
      }
      return prev;
    },
    {
      fallbackTimeslots: [] as Timeslot[],
      overwritingTimeslots: [] as Timeslot[],
    },
  );

  let timeslots = fallbackTimeslots.filter((f) =>
    overwritingTimeslots.every(
      (o) =>
        moment(f.endedAt) <= moment(o.startedAt) ||
        moment(f.startedAt) >= moment(o.endedAt),
    ),
  );

  timeslots = timeslots.concat(overwritingTimeslots).filter((t) => {
    const now = new Date().toISOString();
    return now < t.startedAt;
  });

  return (
    <>
      <TopNavbar
        title="프로 상세"
        left={<BackButton />}
        right={<HomeButton />}
        borderless
      />

      <AspectRatioImage
        src={
          data.user.avatar
            ? data.user.avatar
            : `https://source.unsplash.com/random?sig=${id}`
        }
        alt="pro"
        pb="pb-full"
      >
        <div className="absolute bottom-6 left-5 font-bold text-2xl text-white">
          {data.user.name} 프로
          <br />
          {data.user.title !== null && (
            <div className="text-sm font-normal text-white">
              {data.user.title}
            </div>
          )}
          <div className="flex mt-2 items-center">
            <StarIcon />
            <p className="ml-1 text-sm font-norma text-brand-2">
              후기 {data.reviewsByProId.length}개
            </p>
          </div>
        </div>
      </AspectRatioImage>

      <Tabs tw={{ borderWidth: 'border-0', height: 'h-16' }}>
        <BoxTab
          text="15분 전화 레슨"
          subText={`${data.user.callPrice.toLocaleString()}원`}
          active={reservationType === ReservationType.CALL}
          onClick={() => setReservationType(ReservationType.CALL)}
        />
        <BoxTab
          text="15분 원격 라이브 레슨"
          subText={`${data.user.livePrice.toLocaleString()}원`}
          active={reservationType === ReservationType.LIVE}
          onClick={() => setReservationType(ReservationType.LIVE)}
        />
      </Tabs>

      <Section>
        <DateSelect value={date} onChange={(date) => setDate(date)} />

        {timeslots.length > 0 ? (
          <HorizontalScrollView twOuter={{ marginX: '-mx-5' }}>
            {[...timeslots]
              .sort(
                (a, b) =>
                  new Date(a.startedAt).getHours() -
                  new Date(b.startedAt).getHours(),
              )
              .map((t) => {
                const active = t.id === timeslot?.id;

                return (
                  <CheckButton
                    key={t.id}
                    active={active}
                    onClick={() => {
                      setTimeslot(active ? undefined : t);
                    }}
                  >
                    {utcToLocalFormat(t.startedAt, 'a')}
                    <br />
                    {utcToLocalFormat(t.startedAt, 'h:mm')}
                  </CheckButton>
                );
              })}
          </HorizontalScrollView>
        ) : (
          <div className="py-4 text-center text-sm">
            <p>예약 가능한 시간이 없습니다.</p>
            <p>다른 날짜를 선택해주세요.</p>
          </div>
        )}
      </Section>

      <Divider tw={{ height: 'h-3' }} />

      <Section>
        <h3 className="font-bold text-xl">영상</h3>
        <HorizontalScrollView
          twInner={{
            marginX: '-mx-5',
            marginLeft: 'ml-0',
            alignItems: 'items-baseline',
          }}
        >
          {data.user.proVideos.map((proVideo) => (
            <ProVideoCard
              key={proVideo.id}
              proVideo={proVideo}
              width="w-32"
              subtitle=""
            />
          ))}
        </HorizontalScrollView>
      </Section>

      <Divider tw={{ height: 'h-3' }} />

      <div className="p-5">
        <div className="flex space-x-3">
          {/* <div className="font-bold text-sm">이력</div> */}
          <div
            className="flex-1 text-15 leading-6 whitespace-pre-line break-all"
            dangerouslySetInnerHTML={{ __html: data.user.career }}
          ></div>
        </div>
      </div>

      <Divider tw={{ height: 'h-3' }} />

      <Section>
        <h3 className="font-bold text-xl">
          후기{' '}
          <span className="text-brand-2">{data.reviewsByProId.length}</span>
        </h3>
        {data.user.canReview && me?.role === Role.USER && !data.user.review && (
          <Button
            variant="outlined"
            tw={{
              borderColor: 'border-brand-2',
              color: 'text-brand-2',
              fontSize: 'text-sm',
              height: 'h-12',
            }}
            onClick={(e) => {
              e.stopPropagation();
              history.push(`/pros/${id}/add-review`);
            }}
          >
            후기 쓰기
          </Button>
        )}
        <Divider />
        {data?.reviewsByProId.map((review) => (
          <ReviewCard
            key={review.id}
            review={review}
            me={me}
            refetch={() => refetch()}
          />
        ))}
      </Section>

      <br />
      <br />

      {timeslot && me?.role === Role.USER && (
        <BottomFixedView
          tw={{ backgroundColor: 'bg-gray-100', height: 'h-16' }}
        >
          <div className="flex justify-between items-center space-x-2 px-5 py-3">
            <div className="min-w-max">
              <div className="font-bold">원격 라이브 레슨</div>
              <div className="text-sm text-gray-600">
                {moment(timeslot.startedAt).format('YYYY년 MM월 DD일 HH:mm')}
              </div>
            </div>
            <Button
              tw={{ fontSize: 'text-sm' }}
              to={`/reservations/new?type=${reservationType}&userId=${data.user.id}&date=${date}&timeslotId=${timeslot.id}`}
            >
              예약하기
              <span className="font-normal">{` ${
                reservationType === ReservationType.CALL
                  ? data.user.callPrice.toLocaleString()
                  : data.user.livePrice.toLocaleString()
              }원`}</span>
            </Button>
          </div>
        </BottomFixedView>
      )}
    </>
  );
};
