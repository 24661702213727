import React from 'react';
import {
  BackButton,
  Background,
  Button,
  Divider,
  gql,
  LoadingScreen,
  Section,
  TextArea,
  TopNavbar,
  useIdParam,
  useMutation,
  useQuery,
  useRecoilValue,
  utcToLocalFormat,
} from '@entropyparadox/reusable-react';
import { ReactComponent as MasterCard } from '../assets/images/icon-mastercard.svg';
import { ReservationCard } from '../components';
import { ErrorPage } from './ErrorPage';
import { Reservation, ReservationState, ReservationType, Role } from '../types';
import { NotFoundPage } from './NotFoundPage';
import { useHistory } from 'react-router-dom';
import { meState } from '../store';

const ReservationPageQuery = gql`
  query ReservationPage($id: Int!) {
    reservation(id: $id) {
      id
      createdAt
      targetType
      type
      startedAt
      endedAt
      state
      note
      user {
        id
        name
        avatar
        callPrice
        livePrice
        payment(id: $id) {
          id
          card_name
          card_no
        }
      }
      pro {
        id
        name
        avatar
        callPrice
        livePrice
      }
      room {
        id
        name
        branch {
          id
          name
        }
      }
    }
  }
`;

const CancelReservation = gql`
  mutation CancelReservation($id: Int!) {
    cancelReservation(id: $id) {
      id
    }
  }
`;

// const RefundReservationAndPayment = gql`
//   mutation RefundReservationAndPayment($id: Int!) {
//     refundReservationAndPayment(reservationId: $id) {
//       id
//     }
//   }
// `;

export const ReservationPage = () => {
  const id = useIdParam();
  const history = useHistory();
  const me = useRecoilValue(meState);
  const { loading, error, data } = useQuery<{ reservation: Reservation }>(
    ReservationPageQuery,
    {
      skip: id === undefined,
      variables: { id },
    },
  );

  const handleCancel = () => {
    // eslint-disable-next-line no-restricted-globals
    const isCancel = confirm('정말 취소하시겠습니까?');
    if (isCancel) {
      // refundReservationAndPayment();
      cancelReservation();
    }
  };

  const [cancelReservation] = useMutation(CancelReservation, {
    variables: { id },
    onCompleted: () => history.push('/reservation-saved?result=canceled'),
    onError: (err) => alert(err.message),
  });

  // const [refundReservationAndPayment] = useMutation(
  //   RefundReservationAndPayment,
  //   {
  //     variables: { id },
  //     onCompleted: () => history.push('/reservation-saved?result=canceled'),
  //     onError: (err) => alert(err.message),
  //   },
  // );

  const now = new Date();
  now.setHours(0, 0, 0, 0);

  const reservationTime = new Date(data?.reservation.startedAt ?? 0);
  reservationTime.setHours(0, 0, 0, 0);

  if (!id) return <NotFoundPage />;
  if (loading) return <LoadingScreen />;
  if (error || !data) return <ErrorPage />;

  const callPrice =
    data.reservation.type === ReservationType.CALL &&
    data.reservation.pro.callPrice;

  const livePrice =
    data.reservation.type === ReservationType.LIVE &&
    data.reservation.pro.livePrice;

  return (
    <>
      <Background tw={{ backgroundColor: 'bg-gray-100' }} />

      <TopNavbar title="예약 내역 상세" left={<BackButton />} />

      <Section>
        <h3 className="font-bold">
          {utcToLocalFormat(data.reservation.startedAt, 'LL (dd)')}
        </h3>
        <ReservationCard reservation={data.reservation} simple />
      </Section>

      {/* <Section>
        <h3 className="font-bold text-15">예약요청 시간</h3>
        <p className="font-medium text-sm text-gray-500">
          {utcToLocalFormat(data.reservation.createdAt, 'LL HH:mm')}
        </p>
      </Section> */}

      {data.reservation.type !== ReservationType.ROOM && (
        <Section tw={{ spaceY: 'space-y-2' }}>
          <h3 className="font-bold">코칭 주제</h3>
          <TextArea
            disabled
            value={
              !data.reservation.note
                ? '요청한 내용이 없습니다.'
                : data.reservation.note
            }
          />
        </Section>
      )}

      {data.reservation.type !== ReservationType.ROOM && (
        <Section>
          <h3 className="font-bold text-15">요금 세부정보</h3>
          <div className="flex flex-col space-y-2 font-medium text-sm text-gray-500">
            <div className="flex justify-between">
              <span>비대면 코칭 1시간</span>
              <span>
                ₩
                {String(callPrice || livePrice).replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ',',
                )}
              </span>
            </div>
            <div className="flex justify-between">
              <span className="font-bold text-15">합계 (KRW)</span>
              <span>
                ₩
                {String(callPrice || livePrice).replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ',',
                )}
              </span>
            </div>
          </div>
        </Section>
      )}

      {data.reservation.type !== ReservationType.ROOM &&
        me?.role === Role.USER && (
          <Section>
            <h3 className="font-bold text-15">결제 수단</h3>
            <div className="flex justify-between">
              <span className="font-medium text-sm text-gray-500">
                카드결제
              </span>
              <span className="flex items-center space-x-2 font-medium text-sm">
                <MasterCard />
                <span>
                  {data.reservation.user.payment?.card_name} ····{' '}
                  {data.reservation.user.payment?.card_no}
                </span>
              </span>
            </div>
          </Section>
        )}

      <Section>
        <Divider />
      </Section>

      {/* if reservation is not CANCELED yet and start time is later than now */}
      {data.reservation.state === ReservationState.MADE &&
        data.reservation.type === ReservationType.ROOM && (
          // reservationTime.getTime() > now.getTime() &&
          <Section>
            <Button onClick={() => handleCancel()}>예약 취소하기</Button>
          </Section>
        )}
    </>
  );
};
