import { ListItem, utcToLocalFromNow } from '@entropyparadox/reusable-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Notification, NotificationTargetType } from '../types';

interface NotificationListItemProps {
  notification: Notification;
}

export const NotificationListItem: React.FC<NotificationListItemProps> = ({
  notification,
}) => {
  const { t } = useTranslation();

  let destination = '';
  if (notification.targetType === NotificationTargetType.QUESTION) {
    destination = `/questions/${notification.targetId}`;
  }
  if (notification.targetType === NotificationTargetType.RESERVATION) {
    destination = `/reservations/${notification.targetId}`;
  }

  return (
    <ListItem to={destination} fullBorder>
      <div className="flex justify-between space-x-3">
        <div>
          <div className="font-bold text-left">
            {t(`${notification.code}.title`)}
          </div>
          <div className="text-sm text-gray-600">
            {t(`${notification.code}.text`)}
          </div>
        </div>
        <time className="text-sm text-gray-500">
          {utcToLocalFromNow(notification.createdAt)}
        </time>
      </div>
    </ListItem>
  );
};
