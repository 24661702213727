import {
  Avatar,
  CloseIcon,
  gql,
  IconButton,
  LoadingScreen,
  TopNavbar,
  useIdParam,
  useQuery,
  useRecoilValue,
  useShowAlert,
  utcToLocalTime,
} from '@entropyparadox/reusable-react';
import AgoraRTC, {
  IAgoraRTCClient,
  IMicrophoneAudioTrack,
} from 'agora-rtc-sdk-ng';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ReceiverIcon } from '../assets/images/icon-receiver.svg';
import { meState } from '../store';
import { Reservation, AgoraResponse } from '../types';
import { ErrorPage } from './ErrorPage';
import { NotFoundPage } from './NotFoundPage';

const APP_ID = 'ec3a3424ae1544c483a1952410c8c4f7';

const LivePageQuery = gql`
  query LivePage($id: Int!) {
    reservation(id: $id) {
      id
      startedAt
      endedAt
      user {
        id
        name
        avatar
      }
      pro {
        id
        name
        avatar
      }
    }
    agoraToken(id: $id) {
      token
    }
  }
`;

export const CallPage = () => {
  const id = useIdParam();
  const history = useHistory();
  const showAlert = useShowAlert();
  const me = useRecoilValue(meState);
  const [client, setClient] = useState<IAgoraRTCClient>();
  const [token, setToken] = useState('');
  const [remoteCount, setRemoteCount] = useState(0);
  const [localTrack, setLocalTrack] = useState<IMicrophoneAudioTrack>();
  const [connectedTime, setConnectedTime] = useState(0);
  const [elapsedTime, setElapsedTime] = useState(0);

  useEffect(() => {
    if (client) return;
    const c = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });
    c.on('user-published', async (user, mediaType) => {
      await c.subscribe(user, mediaType);
      user.videoTrack?.play('remote');
      user.audioTrack?.play();
      setRemoteCount((count) => count + 1);
    });
    c.on('user-unpublished', () => setRemoteCount((count) => count - 1));
    setClient(c);
  }, [client]);

  useEffect(() => {
    if (localTrack) return () => localTrack.close();
    AgoraRTC.createMicrophoneAudioTrack().then((track) => setLocalTrack(track));
  }, [localTrack]);

  useEffect(() => {
    if (!id || !me || !client || !localTrack || !token) return;
    client
      .join(APP_ID, id.toString(), token, me.id)
      .then(() => client.publish(localTrack));
    return () => {
      client.leave();
    };
  }, [id, me, client, localTrack, token]);

  const { loading, error, data } = useQuery<{
    reservation: Reservation;
    agoraToken: AgoraResponse;
  }>(LivePageQuery, {
    skip: id === undefined,
    variables: { id },
    onCompleted: ({ agoraToken }) => setToken(agoraToken.token),
  });

  useEffect(() => {
    if (remoteCount === 0) return;
    setConnectedTime(Date.now());
    return () => setConnectedTime(0);
  }, [remoteCount]);

  useEffect(() => {
    if (!data) return;
    if (connectedTime === 0) return;
    const interval = setInterval(() => {
      setElapsedTime(Date.now() - connectedTime);

      if (remoteCount === 0) return;
      const endedAt = moment(data.reservation.endedAt);
      if (endedAt.subtract(5 * 60, 's').isSame(moment(), 's')) {
        showAlert('info', '레슨시간이 5분 남았습니다.');
      } else if (endedAt.isSame(moment(), 's')) {
        showAlert('info', '레슨시간이 종료되었습니다.');
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [data, connectedTime]); // eslint-disable-line

  if (!id) return <NotFoundPage />;
  if (loading || !me) return <LoadingScreen />;
  if (error || !data) return <ErrorPage />;
  return (
    <>
      <div
        className="fixed inset-0 -z-10"
        style={{
          background:
            remoteCount === 0
              ? 'linear-gradient(180deg, #536976 12.75%, #292E49 76.54%)'
              : 'linear-gradient(0deg, #8E9EAB -30.91%, #EEF2F3 114.96%)',
        }}
      />

      {remoteCount === 0 && (
        <TopNavbar
          right={
            <IconButton
              icon={<CloseIcon className="stroke-current text-white" />}
              onClick={() => history.goBack()}
            />
          }
          position="fixed"
          borderless
          backgroundColor="transparent"
        />
      )}

      <div
        className={`fixed inset-0 flex flex-col justify-center items-center
                    space-y-3 ${
                      remoteCount === 0 ? 'text-white' : 'text-gray-800'
                    }`}
      >
        <Avatar
          src={
            data.reservation.user.id === me.id
              ? data.reservation.pro.avatar
              : data.reservation.user.avatar
          }
          size={20}
        />
        <p className="font-bold text-xl">
          {data.reservation.user.id === me.id
            ? `${data.reservation.pro.name} 프로`
            : `${data.reservation.user.name} 회원`}
        </p>
        <p>
          {remoteCount === 0
            ? '비대면 전화 코칭 대기중...'
            : '비대면 전화 코칭이 시작되었습니다.'}
        </p>
        <br />
        <p className="font-bold">
          {remoteCount === 0 ? '시작 시간' : '진행중'}
        </p>
        <p className="font-bold text-5xl">
          {remoteCount === 0
            ? utcToLocalTime(data.reservation.startedAt)
            : moment(elapsedTime).format('mm:ss')}
        </p>
        <br />
        <br />
        <br />
        <br />
        <div>
          {remoteCount > 0 && (
            <button
              className="w-20 h-20 rounded-full flex justify-center items-center bg-red-500"
              onClick={() =>
                // eslint-disable-next-line
                confirm(
                  '정말 종료하시겠습니까?\n확인을 누르면 코칭이 종료됩니다.',
                ) && history.goBack()
              }
            >
              <ReceiverIcon />
            </button>
          )}
        </div>
      </div>
    </>
  );
};
