import { ListItem, utcToLocalDate } from '@entropyparadox/reusable-react';
import React, { FC } from 'react';
import { Question } from '../types';
import { AnsweredIndicator } from './AnsweredIndicator';

interface QuestionListItemProps {
  question: Question;
}

export const QuestionListItem: FC<QuestionListItemProps> = ({ question }) => {
  return (
    <ListItem to={`/questions/${question.id}`}>
      <div className="flex space-x-4">
        <div className="flex-1 flex flex-col min-w-0">
          <div className="flex space-x-1">
            <span className="font-bold text-xs text-gray-500">
              {question.user.name}
            </span>
            <time className="text-xs text-gray-400">
              {utcToLocalDate(question.createdAt)}
            </time>
          </div>

          <span className="truncate text-left font-bold">{question.title}</span>
        </div>

        <AnsweredIndicator answered={question.answered} />
      </div>
    </ListItem>
  );
};
