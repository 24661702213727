import React, { ButtonHTMLAttributes, FC } from 'react';

interface SmallTextButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant: 'save' | 'cancel' | 'edit' | 'delete';
}

export const SmallTextButton: FC<SmallTextButtonProps> = ({
  variant,
  ...props
}) => {
  let text = '';
  let color = 'text-gray-400';
  switch (variant) {
    case 'save':
      text = '등록';
      color = 'text-brand-1';
      break;
    case 'cancel':
      text = '취소';
      break;
    case 'edit':
      text = '수정';
      break;
    case 'delete':
      text = '삭제';
      color = 'text-red-500';
      break;
  }

  return (
    <button {...props} className={`w-10 h-10 text-sm ${color}`}>
      {text}
    </button>
  );
};
