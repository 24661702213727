import {
  BackButton,
  BottomFixedButton,
  BoxTab,
  gql,
  LoadingScreen,
  Section,
  Tabs,
  TextField,
  TopNavbar,
  useMutation,
  useRecoilState,
} from '@entropyparadox/reusable-react';
import React, { useEffect, useState } from 'react';
import { meState } from '../store';
import { ReservationType } from '../types';

const UpdateMe = gql`
  mutation UpdateMe($callPrice: Int, $livePrice: Int) {
    updateMe(callPrice: $callPrice, livePrice: $livePrice) {
      id
    }
  }
`;

export const PricingEditPage = () => {
  const [me, setMe] = useRecoilState(meState);
  const [reservationType, setReservationType] = useState(ReservationType.CALL);
  const [callPrice, setCallPrice] = useState(0);
  const [livePrice, setLivePrice] = useState(0);
  const [modified, setModified] = useState(false);
  useEffect(() => {
    if (me) {
      setCallPrice(me.callPrice);
      setLivePrice(me.livePrice);
    }
  }, [me]);

  const [updateMe] = useMutation(UpdateMe, {
    variables: { callPrice, livePrice },
    onCompleted: () => {
      setMe(undefined);
      setModified(false);
    },
  });

  if (!me) return <LoadingScreen />;
  return (
    <>
      <TopNavbar title="비용 설정" left={<BackButton />} borderless />

      <Tabs sticky tw={{ borderWidth: 'border-0', height: 'h-16' }}>
        <BoxTab
          text="전화 레슨"
          active={reservationType === ReservationType.CALL}
          onClick={() => {
            setReservationType(ReservationType.CALL);
            setModified(false);
          }}
        />
        <BoxTab
          text="원격 라이브 레슨"
          active={reservationType === ReservationType.LIVE}
          onClick={() => {
            setReservationType(ReservationType.LIVE);
            setModified(false);
          }}
        />
      </Tabs>

      <Section>
        {reservationType === ReservationType.CALL && (
          <div className="flex items-end space-x-2">
            <TextField
              type="number"
              min="0"
              label="전화 레슨 비용 (15분)"
              placeholder="비용을 입력해주세요"
              value={callPrice.toString()}
              onChange={(e) => {
                setCallPrice(Number(e.target.value));
                setModified(true);
              }}
            />
            <div className="mb-3">원 / 15분</div>
          </div>
        )}
        {reservationType === ReservationType.LIVE && (
          <div className="flex items-end space-x-2">
            <TextField
              type="number"
              min="0"
              label="원격 라이브레슨 비용 (15분)"
              placeholder="비용을 입력해주세요"
              value={livePrice.toString()}
              onChange={(e) => {
                setLivePrice(Number(e.target.value));
                setModified(true);
              }}
            />
            <div className="mb-3">원 / 15분</div>
          </div>
        )}
      </Section>

      <BottomFixedButton
        tw={{ height: 'h-16' }}
        text="수정 완료"
        disabled={!modified}
        onClick={() => updateMe()}
      />
    </>
  );
};
