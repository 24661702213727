import { useQueryParams } from '@entropyparadox/reusable-react';
import React from 'react';
import { ReservationType } from '../types';
import { NotFoundPage } from './NotFoundPage';
import { ProReservationAddPage } from './ProReservationAddPage';
import { RoomReservationAddPage } from './RoomReservationAddPage';

export const ReservationAddPage = () => {
  const { type } = useQueryParams();

  switch (type) {
    case ReservationType.CALL:
    case ReservationType.LIVE:
      return <ProReservationAddPage reservationType={type} />;
    case ReservationType.ROOM:
      return <RoomReservationAddPage />;
    default:
      return <NotFoundPage />;
  }
};
